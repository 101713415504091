import './AddPanelistModal.less'

import { Form } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Subject, addPanelists, fetchSubjectsNotInProject } from '../../../../../requests'
import { validateRequired } from '../../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal, DatacSelect } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

interface AddPanelistModalProps {
  onClose: (redirect: boolean) => void
  isOpened: boolean
}

export const AddPanelistModal: React.FC<AddPanelistModalProps> = ({ isOpened, onClose }) => {
  const intl = useScopedIntl('')
  const intlPanelists = useScopedIntl('side_by_side.project.add_panelists')
  const [formInstance] = Form.useForm()
  const { project } = useSideBySideProjectDetailsStore()
  const [selectedSubjectIds, setSelectedSubjectIds] = useState<string[]>([])
  const [subjects, setSubjects] = useState<Partial<Subject>[]>([])

  useEffect(() => {
    if (isOpened) {
      formInstance.resetFields()
      setSelectedSubjectIds([])
      setSubjects([])
      fetchSubjects()
    }
  }, [isOpened])

  const onSubmit = ({ subjectIds }: { subjectIds: string[] }) => {
    addPanelists(
      { projectId: project.id, subjectIds },
      {
        onSuccess: () => onClose(true),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const fetchSubjects = debounce(
    (searchPhrase = '') =>
      fetchSubjectsNotInProject(
        { projectId: project.id, searchPhrase },
        {
          onSuccess: newSubjects =>
            setSubjects([
              ...subjects.filter(s => selectedSubjectIds.includes(s.id)),
              ...newSubjects.filter(s => !selectedSubjectIds.includes(s.id))
            ]),
          onRequestError: code => DatacMessage.error(code, intl)
        }
      ),
    500
  )

  const subjectOptions = subjects.map(s => ({
    value: s.id,
    label: s.id,
    sublabel: `${s.firstName || ''} ${s.lastName || ''}`
  }))

  const onSearch = (searchPhrase: string) => {
    fetchSubjects(searchPhrase.trim())
  }

  return (
    <Form form={formInstance} onFinish={onSubmit}>
      <DatacModal
        title={intlPanelists('title')}
        isOpened={isOpened}
        onClose={() => onClose(false)}
        onSubmit={() => formInstance.submit()}
        destroyOnClose
      >
        <div className="add-panelists-modal__body">
          <DatacFormItem
            name="subjectIds"
            label={intlPanelists('label.existing')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              placeholder={intlPanelists('placeholder.existing')}
              options={subjectOptions}
              onSearch={onSearch}
              doNotFilterResults
              onChange={setSelectedSubjectIds}
            />
          </DatacFormItem>
        </div>
      </DatacModal>
    </Form>
  )
}
