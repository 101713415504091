import './FileQuestionControl.less'

import { Button, Upload } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import {
  FileAnswer,
  FileQuestion,
  FileState,
  RcCustomRequestOptions,
  defaultSupportedFileExtensions,
  getUploadUrl,
  uploadFileToS3
} from '../../../../../../../requests'
import { DatacIcon, DatacMessage, DatacUploadFileItem } from '../../../../../../common'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../../TestFormUtils'

export interface FileListItem extends Partial<UploadFile> {
  uid: string
  name: string
  size: number
  state: FileState
}

export enum FileAction {
  Upload = 'UPLOAD',
  Delete = 'DELETE'
}

export type FileUpdateCallback = (
  action: FileAction,
  payload: File | string,
  onChange: (value: FileAnswer[]) => void
) => void

export interface OnFileUploadHandlerOptions {
  file: File
  onSuccess: (newFile: FileAnswer) => void
  onError: (error: string) => void
  filesCount: number
}

export interface OnFileDeleteHandlerOptions {
  fileId: string
  onSuccess: () => void
  onError: (error: string) => void
  filesCount: number
}

interface Props {
  value?: FileAnswer[]
  onChange?: (value: FileAnswer[]) => void
  question: FileQuestion
  productId: string
  onFilesChange: (oldFiles: FileAnswer[], newFiles: FileAnswer[], errors: string[]) => void
  disabled?: boolean
}

type CancelFunction = () => void

const contextProps: PropsFromContextKeys = [
  'deleteFile',
  'saveFile',
  'downloadFile',
  'maxFileBytes',
  'updateRecord',
  'maxFilesNumber'
]

const FileQuestionControlInner: React.FC<Props & PropsFromContext> = ({
  value,
  onChange,
  question,
  productId,
  onFilesChange,
  disabled = false,
  deleteFile,
  saveFile,
  downloadFile,
  maxFileBytes,
  updateRecord,
  maxFilesNumber
}) => {
  const [cancelers, setCancelers] = useState<Record<string, CancelFunction>>({})
  const [fileList, setFileList] = useState<FileAnswer[]>(value || [])
  const errors = useRef<string[]>([])
  const fileQuestionIntl = useScopedIntl('studies.fulfillment.question.file')
  const intlApi = useScopedIntl('api')
  const intl = useScopedIntl('')

  useEffect(() => {
    const newFileList = value || []
    setFileList(newFileList)
  }, [value])

  useEffect(() => {
    if (fileList.length && !fileList.some(file => [FileState.Uploading, FileState.Deleting].includes(file.state))) {
      onChange(fileList)
      onFilesChange(fileList, fileList, errors.current)
    }
  }, [fileList])

  const getFileListWithoutFile = (fileId: string, fileList: FileAnswer[]) => fileList.filter(file => file.id !== fileId)

  const changeFileStatus = (fileId: string, status: FileState, fileList: FileAnswer[]) =>
    fileList.map(file => (file.id === fileId ? { ...file, state: status } : file))

  const getFileListWithFile = (file: FileAnswer, fileList: FileAnswer[]) => [...fileList, file]

  const getFileListWithUpdatedFileInfo = (fileId: string, newFile: FileAnswer, fileList: FileAnswer[]) =>
    fileList.map(file => (file.id === fileId ? { ...newFile } : file))

  const onDelete = (fileId: string, filename: string) => {
    errors.current = []
    setFileList(fileList => changeFileStatus(fileId, FileState.Deleting, fileList))

    deleteFile(
      { fileId },
      {
        onSuccess: () => {
          const newFileList = getFileListWithoutFile(fileId, fileList)
          onFilesChange(fileList, newFileList, errors.current)
          onChange(newFileList.length ? newFileList : undefined)
          updateRecord()
        },
        onRequestError: code => {
          const error = `${intlApi(String(code))}: ${filename}`
          errors.current = [...errors.current, error]
          setFileList(fileList => changeFileStatus(fileId, FileState.Ready, fileList))
        }
      }
    )
  }

  const uploadControlCustomRequest = (options: RcCustomRequestOptions) => {
    if (options.file.size > maxFileBytes) {
      errors.current = [`${intlApi('9002')}: ${options.file.name}`]
      onFilesChange(fileList, fileList, errors.current)
      return
    }

    errors.current = []
    getUploadUrl(
      { name: options.file.name, size: options.file.size },
      {
        onSuccess: (uploadUrl, fileId) => {
          uploadFile(uploadUrl, fileId, options)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const uploadFile = (uploadUrl: string, fileId: string, options: RcCustomRequestOptions) => {
    setFileList(fileList =>
      getFileListWithFile(
        {
          id: fileId,
          name: options.file.name,
          size: options.file.size,
          state: FileState.Uploading
        },
        fileList
      )
    )

    const cancel = uploadFileToS3(
      { uploadUrl, file: options.file },
      {
        onProgressUpdate({ size, uploadedSize }) {
          setFileList(fileList =>
            getFileListWithUpdatedFileInfo(
              fileId,
              {
                id: fileId,
                name: options.file.name,
                size,
                uploadedSize,
                state: FileState.Uploading
              },
              fileList
            )
          )
        },
        onUploaded: () => {
          saveAnswer(fileId, options)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
        }
      }
    )

    setCancelers(cancelers => ({ ...cancelers, [fileId]: cancel }))
  }

  const onCancelUpload = (fileId: string) => {
    setFileList(fileList => fileList.filter(file => file.id !== fileId))
    cancelers[fileId]()
  }

  const saveAnswer = (fileId: string, options: RcCustomRequestOptions) => {
    saveFile(
      { questionId: question.id, productId, fileId },
      {
        onSuccess: savedFile => {
          setFileList(fileList =>
            getFileListWithUpdatedFileInfo(
              fileId,
              {
                id: savedFile.id,
                name: savedFile.name,
                size: savedFile.size
              },
              fileList
            )
          )
          updateRecord()
        },
        onRequestError: code => {
          const error = `${intlApi(String(code))}: ${options.file.name}`
          errors.current = [...errors.current, error]
          setFileList(fileList => getFileListWithoutFile(fileId, fileList))
        }
      }
    )
  }

  const uploadedFiles = fileList.map(file => ({
    uid: file.id,
    name: file.name,
    size: file.size,
    uploadedSize: file.uploadedSize,
    state: file.state || FileState.Ready,
    type: 'any'
  }))

  const sizeLimitInMegabytes = Math.floor(maxFileBytes / 1048576).toString()

  return (
    <>
      <Upload
        fileList={uploadedFiles}
        name="file"
        accept={defaultSupportedFileExtensions.map(extension => `.${extension}`).join(',')}
        // @ts-ignore
        customRequest={uploadControlCustomRequest}
        itemRender={(_, file) => (
          <DatacUploadFileItem
            className={classNames(
              'test-form-file-question-control__file-item',
              (file as FileListItem).state === FileState.Uploading &&
                'test-form-file-question-control__file-item--uploading'
            )}
            isCompact
            canDownload={!!downloadFile && (file as FileListItem).state !== FileState.Uploading}
            canDelete={!disabled}
            deleteDisabled={(file as FileListItem).state === FileState.Deleting}
            downloadFile={downloadFile}
            onDelete={() => onDelete(file.uid, file.name)}
            onCancelUpload={() => onCancelUpload(file.uid)}
            file={file}
          />
        )}
        multiple
        className={classNames(
          'test-form-file-question-control',
          disabled && 'test-form-file-question-control--disabled'
        )}
      >
        {uploadedFiles.length < maxFilesNumber && (
          <Button className="test-form-file-question-control__upload-button" disabled={disabled}>
            <DatacIcon raw name="uploadCloud" />
            <div className="test-form-file-question-control__upload-label">
              <span className="label">{fileQuestionIntl('upload_label')}</span>
              <span className="max-size">{fileQuestionIntl('max_size', { limit: sizeLimitInMegabytes })}</span>
            </div>
          </Button>
        )}
      </Upload>
    </>
  )
}

export const FileQuestionControl = TestFormContextWrapper<Props>(FileQuestionControlInner, contextProps)
