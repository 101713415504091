import './DatetimeQuestionControl.less'

import { DatePicker, TimePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import { DateTimeQuestion, DateTimeSubtype } from '../../../../../../../requests'
import {
  disableFutureTimeHelper,
  getDateTimeSubtypeFormat,
  getSwitchableDateType,
  parseInputDateTime,
  parseOutputDateTime
} from '../../../../../../../utils'
import { DatacIcon, DatacSelect } from '../../../../../../common'
import { StudyDetailsContext } from '../../../../../../studies'

interface ControlProps {
  value: string
  subtype?: DateTimeSubtype
  onChange: (date: string) => void
  disabled?: boolean
  disableFutureDatetime: boolean
  format: string
}

const DateControl: React.FC<ControlProps> = ({
  value,
  onChange,
  subtype,
  disabled = false,
  disableFutureDatetime,
  format
}) => {
  const picker = () => {
    switch (subtype) {
      case DateTimeSubtype.Year:
        return 'year'
      case DateTimeSubtype.YearMonth:
        return 'month'
      default:
        return 'date'
    }
  }

  return (
    <DatePicker
      size="large"
      format={format}
      suffixIcon={<DatacIcon name="calendar" type="blue" />}
      onChange={date => onChange(parseOutputDateTime(date, subtype))}
      value={parseInputDateTime(value, subtype)}
      disabled={disabled}
      picker={picker()}
      disabledDate={date => disableFutureDatetime && date.isAfter(dayjs.utc(), 'day')}
    />
  )
}

const TimeControl: React.FC<ControlProps> = ({ value, onChange, disabled = false, disableFutureDatetime, format }) => (
  <TimePicker
    size="large"
    format={format}
    suffixIcon={<DatacIcon name="clock" type="blue" />}
    onChange={date => onChange(parseOutputDateTime(date, DateTimeSubtype.Time))}
    value={parseInputDateTime(value, DateTimeSubtype.Time)}
    disabled={disabled}
    disabledHours={() => (disableFutureDatetime ? disableFutureTimeHelper({ type: 'hours' }) : [])}
    disabledMinutes={selectedHour =>
      disableFutureDatetime ? disableFutureTimeHelper({ type: 'minutes', selectedHour }) : []
    }
  />
)

const DateTimeControl: React.FC<ControlProps> = ({
  value,
  onChange,
  disabled = false,
  disableFutureDatetime,
  format
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(null)
  const [selectedTime, setSelectedTime] = useState<Dayjs>(null)

  useEffect(() => {
    setSelectedDate(parseInputDateTime(value, DateTimeSubtype.DateTime))
    setSelectedTime(parseInputDateTime(value, DateTimeSubtype.DateTime))
  }, [value])

  const getNewDatetime = (date: Dayjs, time: Dayjs) =>
    date && time
      ? `${parseOutputDateTime(date, DateTimeSubtype.Date)}T${parseOutputDateTime(time, DateTimeSubtype.Time)}`
      : ''

  const onDatetimeChange = (date: Dayjs, time: Dayjs) => {
    setSelectedDate(date)
    setSelectedTime(time)
    onChange(getNewDatetime(date, time))
  }

  const onDateChange = (newDate: Dayjs) => {
    const now = dayjs()

    let newTime = selectedTime || now
    if (newDate) {
      const dateTime = dayjs(`${newDate} ${selectedTime}`)
      if (dateTime.isAfter(dayjs())) newTime = now
    }

    onDatetimeChange(newDate, newTime)
  }

  const onTimeChange = (newTime: Dayjs) => {
    const newDate = newTime ? selectedDate || dayjs() : undefined
    onDatetimeChange(newDate, newTime)
  }

  return (
    <div className="test-form-datetime-question-control__datetime">
      <DatePicker
        size="large"
        format={format}
        showToday={false}
        suffixIcon={<DatacIcon name="calendar" type="blue" />}
        onChange={onDateChange}
        value={selectedDate}
        disabled={disabled}
        disabledDate={date => disableFutureDatetime && date.isAfter(dayjs(), 'day')}
      />
      <TimePicker
        size="large"
        format={getDateTimeSubtypeFormat()[DateTimeSubtype.Time]}
        suffixIcon={<DatacIcon name="clock" type="blue" />}
        onChange={onTimeChange}
        value={selectedTime}
        disabled={disabled}
        disabledHours={() => (disableFutureDatetime ? disableFutureTimeHelper({ type: 'hours', selectedDate }) : [])}
        disabledMinutes={selectedHour =>
          disableFutureDatetime ? disableFutureTimeHelper({ type: 'minutes', selectedDate, selectedHour }) : []
        }
      />
    </div>
  )
}

type Value = string

interface Props {
  question: DateTimeQuestion
  onChange: (value: Value) => void
  value?: Value
  disabled?: boolean
}

export const DatetimeQuestionControl: React.FC<Props> = ({
  question: {
    config: { subtype, disableFutureDatetime }
  },
  value,
  onChange,
  disabled = false
}) => {
  const [switchableType, setSwitchableType] = useState(DateTimeSubtype.Date)
  const intlDatetime = useScopedIntl('studies.builder.question_builder.datetime_type')
  const { study } = useContext(StudyDetailsContext)

  const switchableOptions = [DateTimeSubtype.Year, DateTimeSubtype.YearMonth, DateTimeSubtype.Date].map(val => ({
    value: val,
    label: intlDatetime(val.toLowerCase())
  }))

  const subtypeFormat = getDateTimeSubtypeFormat(study?.dateFormat)

  useEffect(() => {
    dayjs.tz.setDefault()

    if (value && subtype === DateTimeSubtype.SwitchableDate) {
      setSwitchableType(getSwitchableDateType(value))
    }
  }, [subtype, value])

  const onSwitchableTypeChange = (newType: DateTimeSubtype) => {
    setSwitchableType(newType)
    if (value) onChange(parseOutputDateTime(parseInputDateTime(value, newType), newType))
  }

  const commonProps = { value, onChange, disabled, subtype, disableFutureDatetime }

  return (
    <div className="test-form-datetime-question-control">
      {subtype === DateTimeSubtype.SwitchableDate && (
        <div className="test-form-datetime-question-control__switchable">
          <DatacSelect
            onChange={onSwitchableTypeChange}
            options={switchableOptions}
            value={switchableType}
            size="middle"
            disabled={disabled}
          />
          <DateControl {...commonProps} format={subtypeFormat[switchableType]} subtype={switchableType} />
        </div>
      )}
      {[DateTimeSubtype.Date, DateTimeSubtype.Year, DateTimeSubtype.YearMonth].includes(subtype) && (
        <DateControl {...commonProps} format={subtypeFormat[subtype]} />
      )}
      {subtype === DateTimeSubtype.Time && <TimeControl {...commonProps} format={subtypeFormat[subtype]} />}
      {subtype === DateTimeSubtype.DateTime && (
        <DateTimeControl {...commonProps} format={subtypeFormat[DateTimeSubtype.Date]} />
      )}
    </div>
  )
}
