import './CheckboxQuestionControl.less'

import { Checkbox, Input } from 'antd'
import React from 'react'

import { SelectQuestion } from '../../../../../../../requests'

export interface Value {
  selections: string[]
  other?: string
}

interface Props {
  question: SelectQuestion
  onChange: (value: Value) => void
  value?: Value
  disabled?: boolean
}

const emptyValue = {
  selections: [] as string[],
  other: null as string
}

export const CheckboxQuestionControl: React.FC<Props> = ({ question, onChange, value, disabled = false }) => {
  const onOtherCheckBoxChange = (checked: boolean) => {
    onChange({ ...emptyValue, ...value, other: checked ? '' : null })
  }

  const onOtherInputChange = (other: string) => {
    onChange({ ...emptyValue, ...value, other })
  }

  const onSelectionsChange = (selections: string[]) => {
    onChange({ ...emptyValue, ...value, selections })
  }

  return (
    <div className="test-form-checkbox-question-control">
      <Checkbox.Group
        options={question.config.options}
        value={value?.selections || []}
        onChange={selections => onSelectionsChange(selections as string[])}
        disabled={disabled}
      />
      {!!question.config.other && (
        <>
          <div className="test-form-other">
            <Checkbox
              className="test-form-checkbox-question-control__other-checkbox"
              checked={!(value?.other === null || value?.other === undefined)}
              onChange={e => onOtherCheckBoxChange(e.target.checked)}
              disabled={disabled}
            >
              {question.config.otherLabel || 'Other'}
            </Checkbox>
          </div>
          {!(value?.other === null || value?.other === undefined) && (
            <Input
              className="test-form-checkbox-question-control__other-input"
              size="large"
              onChange={e => onOtherInputChange(e.target.value)}
              defaultValue={value?.other}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  )
}
