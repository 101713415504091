import './TestFormFooter.less'

import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { RecordStatus, Subsection as SubsectionType } from '../../../../../../requests'
import { DatacIcon, DatacMessage } from '../../../../../common'
import { TestFormAction } from '../TestFormReducer'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  subsections: SubsectionType[]
  onNavigateSubsection: (subsectionId: string) => void
  onNavigateEndTest: () => void
}

const contextProps: PropsFromContextKeys = [
  'record',
  'unansweredRequiredQuestions',
  'requiredQuestionsAlert',
  'subsectionId',
  'testFormDispatch'
]

const TestFormFooterInner: React.FC<Props & PropsFromContext> = ({
  subsections,
  onNavigateSubsection,
  onNavigateEndTest,
  record,
  unansweredRequiredQuestions,
  requiredQuestionsAlert,
  subsectionId,
  testFormDispatch
}) => {
  const intlEcrf = useScopedIntl('studies.fulfillment')
  const intlCommon = useScopedIntl('common')
  const activeSubsectionIndex = subsections.findIndex(subsection => subsection.id === subsectionId)
  const nextSubsection = subsections?.[activeSubsectionIndex + 1]
  const previousSubsection = subsections?.[activeSubsectionIndex - 1]

  const onRequireCheck = (navFunction: () => void) => {
    if (record.status === RecordStatus.Ended || !unansweredRequiredQuestions.size || requiredQuestionsAlert)
      navFunction()
    else {
      testFormDispatch({
        type: TestFormAction.SET_REQUIRED_QUESTION_ALERT,
        requiredQuestionsAlert: true
      })
      DatacMessage.error(intlEcrf('error.required_questions.title'), intlEcrf('error.required_questions.body'))
    }
  }

  const nextButtonWarning = requiredQuestionsAlert && !!unansweredRequiredQuestions.size

  return (
    <div className="test-form-footer">
      <div className="test-form-footer__wrapper">
        <Button
          className="test-form-footer__previous"
          size="large"
          type="default"
          disabled={!previousSubsection}
          onClick={() => onNavigateSubsection(previousSubsection.id)}
        >
          <DatacIcon name="chevronLeft" size="xbig" type={previousSubsection ? 'blue' : 'white-on-semi-transparent'} />
          <span>{intlCommon('back')}</span>
        </Button>
        {(record.status !== RecordStatus.Ended || nextSubsection) && (
          <Button
            role="tab"
            className={classNames('test-form-footer__next', {
              'datac-button--warning': nextButtonWarning
            })}
            size="large"
            type="primary"
            onClick={() =>
              onRequireCheck(() => (nextSubsection ? onNavigateSubsection(nextSubsection.id) : onNavigateEndTest()))
            }
          >
            <span>{intlCommon('next')}</span>
            <DatacIcon name="chevronRight" size="xbig" type="white-on-semi-transparent" />
          </Button>
        )}
      </div>
    </div>
  )
}

export const TestFormFooter = TestFormContextWrapper<Props>(TestFormFooterInner, contextProps)
