import './TestZonesSelected.less'

import classNames from 'classnames'
import React, { useEffect } from 'react'

import Head from '../../../../../../assets/images/sbs/sbs_head.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { Product } from '../../../../../../requests'
import { useSideBySideProjectDetailsStore } from '../../../SideBySideProjectDetailsStore'
import { SelectedProduct } from './SelectedProduct'
import { ZoneArea, topPositions, zoneAreasMap } from './testZoneAreas'

interface TestZonesProductsProps {
  onRemoveProduct: (product: Product) => void
  selectedProducts: Product[]
  onSortProduct: (oldIndex: number, newIndex: number) => void
}

export const TestZonesSelected: React.FC<TestZonesProductsProps> = ({
  selectedProducts,
  onRemoveProduct,
  onSortProduct
}) => {
  const [zoneAreas, setZoneAreas] = React.useState<ZoneArea[]>(null)
  const { project } = useSideBySideProjectDetailsStore()
  const [selectedProductId, setSelectedProductId] = React.useState<number>(null)
  const intlZones = useScopedIntl('side_by_side.project.test.zones')

  useEffect(() => {
    if (selectedProducts.length === 0) {
      setZoneAreas([])
      return
    }

    const zoneAreas = zoneAreasMap.find(
      z => z.areas.length === selectedProducts.length && z.categories.includes(project.category)
    )?.areas

    setZoneAreas(zoneAreas?.map((area, index) => ({ ...area, type: selectedProducts[index].type })))
  }, [selectedProducts])

  const productBoxes = (selection: 'all' | 'top' | 'bottom') =>
    selectedProducts.map((product, index) => {
      const area = zoneAreas?.[index]
      if (
        (selection === 'top' && !topPositions.includes(area?.position)) ||
        (selection === 'bottom' && topPositions.includes(area?.position))
      ) {
        return null
      }

      return (
        <SelectedProduct
          key={product.id}
          product={product}
          area={zoneAreas?.[index]}
          number={index + 1}
          onRemove={() => onRemoveProduct(product)}
          onClick={() => setSelectedProductId(product.id)}
          isSelected={selectedProductId === product.id}
          onMoveLeft={index > 0 ? () => onSortProduct(index, index - 1) : null}
          onMoveRight={index < selectedProducts.length - 1 ? () => onSortProduct(index, index + 1) : null}
        />
      )
    })

  return (
    <div className="test-zones-selected">
      <div className="test-zones-selected__box">
        <div className="test-zones-selected__box__main-title">{intlZones('reorder_your_products')}</div>
        <div className="test-zones-selected__box__content">
          {selectedProducts?.length <= 3 ? (
            <div className="test-zones-selected__box__row">{productBoxes('all')}</div>
          ) : (
            <>
              <div className="test-zones-selected__box__title">{intlZones('top')}:</div>
              <div className="test-zones-selected__box__row">{productBoxes('top')}</div>
              <div className="test-zones-selected__box__title">{intlZones('bottom')}:</div>
              <div className="test-zones-selected__box__row">{productBoxes('bottom')}</div>
            </>
          )}
        </div>
      </div>
      <div className="test-zones-selected__box">
        <div className="test-zones-selected__box__main-title">{intlZones('preview')}</div>
        <div className="test-zones-selected__wrapper">
          <Head className="test-zones-selected__head" />
          {zoneAreas?.map((area, index) => (
            <div
              className={`test-zones-selected__image test-zones-selected__image--${area?.type?.toLowerCase()}`}
              key={index}
            >
              <area.component />
              <div
                className={classNames(
                  'test-zones-selected__number',
                  `test-zones-selected__number--${area.type?.toLowerCase()}`,
                  'test-zones-selected__image__number',
                  { 'test-zones-selected__image__number--top': topPositions.includes(area?.position) }
                )}
              >
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
