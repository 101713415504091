import './TestFormProducts.less'

import { Popover } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Product, ProjectCategory, projectCategoryProductKeyMap } from '../../../../../../requests'
import { DatacIcon } from '../../../../../common'
import { useSideBySideProjectDetailsStore } from '../../../SideBySideProjectDetailsStore'
import { ZoneArea, zoneAreasMap } from '../../SideBySideProjectTestZones'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

const Record: React.FC<{ label: string; value: string | number }> = ({ label, value }) => {
  return (
    <div className="test-form-products__body__product__record">
      <span className="test-form-products__body__product__record__label">{label}</span>
      <span className="test-form-products__body__product__record__value">
        <Popover
          content={<div className="test-form-products__body__product__record__value__popover">{value}</div>}
          trigger={typeof value === 'string' && value?.length > 20 ? 'hover' : null}
          placement="topLeft"
        >
          {value || '-'}
        </Popover>
      </span>
    </div>
  )
}

const contextProps: PropsFromContextKeys = ['record', 'showProductsLeftArrow', 'showProductsRightArrow', 'columnWidth']

export const TestFormProductsInner: React.FC<PropsFromContext> = ({
  record,
  showProductsLeftArrow,
  showProductsRightArrow,
  columnWidth
}) => {
  const intlProducts = useScopedIntl('side_by_side.products.table')
  const intlStatus = useScopedIntl('status')
  const intlCommon = useScopedIntl('common')
  const [isMoreHidden, setIsMoreHidden] = useState(true)
  const { project, testContentRef } = useSideBySideProjectDetailsStore()
  const [zoneAreas, setZoneAreas] = useState<ZoneArea[]>([])
  const intlPosition = useScopedIntl('side_by_side.project.test.zones.position')
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    if (!project?.id || !record?.products?.length) return

    setZoneAreas(
      zoneAreasMap.find(z => z.areas.length === record?.products.length && z.categories.includes(project.category))
        ?.areas
    )
  }, [record?.products, project?.id])

  const handleScroll = () => {
    setScrollTop(document.body.scrollTop)
  }

  useEffect(() => {
    if (!testContentRef) return () => null
    handleScroll()
    document.body.addEventListener('scroll', handleScroll)
    return () => document.body.removeEventListener('scroll', handleScroll)
  }, [])

  const onMove = (direction: 'left' | 'right') => {
    if (!record?.products.length) return

    const currentProductNumber = Math.floor((testContentRef.scrollLeft + 1) / columnWidth)
    const newOffset = currentProductNumber + (direction === 'left' ? -1 : 1)
    testContentRef.scrollTo({ left: newOffset * columnWidth, behavior: 'smooth' })
  }

  const getLabelString = (key: keyof Product) => {
    switch (key) {
      case 'productName':
        return intlProducts('product_name')
      case 'shadeName':
        return intlProducts('shade_name')
      case 'devVol':
        return intlProducts('dev_vol')
      case 'mixRatio':
        return intlProducts('mix_ratio')
      case 'processingTime':
        return intlProducts('processing_time')
      case 'specialInstructions':
        return intlProducts('special_instructions')
      case 'specialApplication':
        return intlProducts('special_application')
      default:
        return intlProducts(key)
    }
  }

  const productRecord = (key: keyof Product, value: string | number) =>
    projectCategoryProductKeyMap[project.category as ProjectCategory].includes(key) && (
      <Record label={getLabelString(key)} value={value} />
    )

  const allRecordsHeight = (projectCategoryProductKeyMap[project.category as ProjectCategory].length + 2) * 3
  return (
    <>
      <div className="test-form-products" style={{ top: `${scrollTop}px` }}>
        <div
          className="test-form-products__body"
          style={{ height: isMoreHidden ? '16rem' : `${allRecordsHeight}rem` }} // we use inline style to make smooth transition
        >
          {record?.products.map((product, index) => (
            <div
              className="test-form-products__body__product"
              key={index}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              {productRecord('formula', product?.formula)}
              {productRecord('productName', product?.productName)}
              {productRecord('category', product?.category)}
              <Record
                label={intlProducts('zone')}
                value={zoneAreas[index]?.position && intlPosition(zoneAreas[index]?.position)}
              />
              <div
                className={classNames(
                  'test-form-products__body__product__more',
                  isMoreHidden && 'test-form-products__body__product__more--hidden'
                )}
              >
                {productRecord('brand', product?.brand)}
                {productRecord('type', product?.type && intlStatus(product.type.toLowerCase()))}
                {productRecord('technology', product?.technology)}
                {productRecord('status', product?.status && intlStatus(product.status.toLowerCase()))}
                {productRecord('shadeName', product?.shadeName)}
                {productRecord('batch', product?.batch)}
                {productRecord('devVol', product?.devVol)}
                {productRecord('mixRatio', product?.mixRatio)}
                {productRecord('quantity', product?.quantity)}
                {productRecord('processingTime', product?.processingTime)}
                {productRecord('specialInstructions', product?.specialInstructions)}
                {productRecord('specialApplication', product?.specialApplication)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="test-form-products__details"
        style={{ top: `${scrollTop}px`, marginTop: isMoreHidden ? '16rem' : `${allRecordsHeight}rem` }}
        onClick={() => setIsMoreHidden(!isMoreHidden)}
      >
        <DatacIcon name={isMoreHidden ? 'chevronDown' : 'chevronUp'} raw />
        <div>{intlCommon('details')}</div>
      </div>
      {showProductsLeftArrow && (
        <div
          style={{ top: `${scrollTop}px`, marginTop: isMoreHidden ? '8rem' : `${allRecordsHeight / 2}rem` }}
          className="test-form-products__previous"
        >
          <DatacIcon name="chevronLeft" type="blue" onClick={() => onMove('left')} />
        </div>
      )}
      {showProductsRightArrow && (
        <div
          style={{ top: `${scrollTop}px`, marginTop: isMoreHidden ? '8rem' : `${allRecordsHeight / 2}rem` }}
          className="test-form-products__next"
        >
          <DatacIcon name="chevronRight" type="blue" onClick={() => onMove('right')} />
        </div>
      )}
    </>
  )
}

export const TestFormProducts = TestFormContextWrapper<{}>(TestFormProductsInner, contextProps)
