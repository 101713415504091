import './RadioQuestionControl.less'

import { Input, Radio } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { Arrangement, SelectQuestion } from '../../../../../../../requests'

export interface Value {
  selected: string
  other?: string
}

interface Props {
  question: SelectQuestion
  onChange: (value: Value) => void
  value?: Value
  disabled?: boolean
}

export const RadioQuestionControl: React.FC<Props> = ({ question, value, onChange, disabled = false }) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [isOtherInvalid, setIsOtherInvalid] = useState(false)
  const otherInput = useRef(null)

  useEffect(() => {
    if (isOtherSelected) {
      setIsOtherInvalid(false)
      otherInput.current.focus()
    }
  }, [isOtherSelected])

  const onOtherBlur = () => {
    if (!otherInput.current.input.value) {
      setIsOtherInvalid(true)
    }
  }

  const onOtherRadioChange = () => {
    setIsOtherSelected(true)
  }

  const onOtherInputChange = debounce((other: string) => {
    if (other) {
      onChange({ selected: '', other })
    }
    setIsOtherInvalid(!other)
  }, 500)

  const onSelectionChange = (selected: string) => {
    onChange({ selected, other: null })
    setIsOtherSelected(false)
  }

  return (
    <div
      className={classNames('test-form-radio-question-control', {
        'test-form-radio-question-control__horizontal': question.config.arrangement === Arrangement.Horizontal
      })}
    >
      <Radio.Group
        options={question.config.options}
        value={isOtherSelected ? null : value?.selected || null}
        onChange={e => onSelectionChange(e.target.value)}
        disabled={disabled}
        className={
          question.config.arrangement === Arrangement.Horizontal && 'test-form-radio-question-control__horizontal'
        }
      />
      {!!question.config.other && (
        <>
          <div className="test-form-other">
            <Radio
              className="test-form-radio-question-control__other-radio"
              checked={!!(value?.selected === '') || isOtherSelected}
              onChange={onOtherRadioChange}
              disabled={disabled}
            >
              {question.config.otherLabel || 'Other'}
            </Radio>
          </div>
          {(value?.selected === '' || isOtherSelected) && (
            <Input
              className={`test-form-radio-question-control__other-input ${
                isOtherInvalid ? 'test-form-radio-question-control__other-input--invalid' : ''
              }`}
              size="large"
              ref={ref => {
                otherInput.current = ref
              }}
              onChange={e => onOtherInputChange(e.target.value)}
              defaultValue={value?.other}
              onBlur={onOtherBlur}
              aria-invalid={isOtherInvalid}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  )
}
