import { Column, Radar } from '@ant-design/plots'
import classNames from 'classnames'
import React from 'react'

import { SideBySideChart, SideBySideChartType } from '../../../../requests'
import { DatacDropdownMenu, DatacDropdownMenuOptions } from '../../../common'
import { getColumnMinimalConfig, getRadarMinimalConfig } from './chartConfig'

enum ChartMenuType {
  Edit = 'edit',
  Delete = 'delete'
}

interface Props {
  chart: SideBySideChart
  onEdit: () => void
  onDelete: () => void
  disabled: boolean
}

export const ChartListItem: React.FC<Props> = ({ chart, onEdit, onDelete, disabled }) => {
  const onChartMenuClick = (item: ChartMenuType) => {
    switch (item) {
      case ChartMenuType.Edit:
        return onEdit()
      case ChartMenuType.Delete:
        return onDelete()
      default:
        return null
    }
  }

  const chartMenuOptions: DatacDropdownMenuOptions<ChartMenuType> = [
    {
      type: ChartMenuType.Edit,
      label: 'common.edit',
      icon: 'edit'
    },
    {
      type: ChartMenuType.Delete,
      label: 'common.delete',
      icon: 'trash',
      customClass: 'dropdown-menu__item--red'
    }
  ]

  const onBoxClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!(e.target as HTMLElement).closest('.ant-dropdown-trigger, .ant-dropdown')) {
      onEdit()
    }
  }

  return (
    <div
      className={classNames('side_by_side-project-reports__item', {
        'side_by_side-project-reports__item--disabled': disabled
      })}
      onClick={onBoxClick}
      role="button"
      tabIndex={0}
    >
      <DatacDropdownMenu options={chartMenuOptions} onClick={key => onChartMenuClick(key as ChartMenuType)} />
      <h3>{chart.title}</h3>
      <div className="side_by_side-project-reports__item__chart-preview">
        {chart.type === SideBySideChartType.Column && (
          <Column {...getColumnMinimalConfig(chart.data, chart.formulas, chart.questions.length, chart.axis)} />
        )}
        {chart.type === SideBySideChartType.Radar && (
          <Radar {...getRadarMinimalConfig(chart.data, chart.formulas, chart.questions.length, chart.axis)} />
        )}
      </div>
    </div>
  )
}
