import './SideBySideProjectDetailsLayout.less'

import { Button, Tabs } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { DatacIcon, DatacStudyStatusTag, DatacTitle } from '../../../common'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'
import { AddPanelistModal } from './AddPanelistModal'
import { AddProductModal } from './AddProductModal'
import { AssignProductsModal } from './AssignProductsModal'
import { SelectFormModal } from './SelectFormModal'

export enum SideBySideProjectDetailsTab {
  Tests = 'tests',
  Reports = 'reports',
  Products = 'products',
  Settings = 'settings'
}

export enum NewProductFormType {
  Add = 'add',
  Assign = 'assign'
}

interface Props {
  children: React.ReactNode
  currentTab: SideBySideProjectDetailsTab
}

export const SideBySideProjectDetailsLayout: React.FC<Props> = ({ children, currentTab }) => {
  const { project, triggerReloadTestsTable, triggerReloadProductsTable } = useSideBySideProjectDetailsStore()
  const [isAddPanelistModalOpened, setIsAddPanelistModalOpened] = useState(false)
  const [isAddProductModalOpened, setIsAddProductModalOpened] = useState(false)
  const [isAssignProductsModalOpened, setIsAssignProductsModalOpened] = useState(false)
  const [isSelectFormModalOpened, setIsSelectFormModalOpened] = useState(false)
  const intlPanelists = useScopedIntl('side_by_side.project.add_panelists')
  const intlFormula = useScopedIntl('side_by_side.products.formula')
  const intlHeader = useScopedIntl('side_by_side.project.header')

  const changeTab = (tab: SideBySideProjectDetailsTab) => {
    switch (tab) {
      case SideBySideProjectDetailsTab.Settings:
        return navigate(routes.sideBySideProjectSettings(project.id))
      case SideBySideProjectDetailsTab.Products:
        return navigate(routes.sideBySideProjectProducts(project.id))
      case SideBySideProjectDetailsTab.Reports:
        return navigate(routes.sideBySideProjectReports(project.id))
      default:
        return navigate(routes.sideBySideProject(project.id))
    }
  }

  const onSelectFormModalClose = (next?: NewProductFormType) => {
    setIsSelectFormModalOpened(false)
    if (next === NewProductFormType.Add) setIsAddProductModalOpened(true)
    if (next === NewProductFormType.Assign) setIsAssignProductsModalOpened(true)
  }

  const onNewProductModalClose = (redirect: boolean) => {
    triggerReloadProductsTable()
    setIsAddProductModalOpened(false)
    if (redirect) navigate(routes.sideBySideProjectProducts(project.id))
  }

  const onAssignProductsModalClose = (redirect: boolean) => {
    triggerReloadProductsTable()
    setIsAssignProductsModalOpened(false)
    if (redirect) navigate(routes.sideBySideProjectProducts(project.id))
  }

  const onNewSubjectModalClose = (redirect: boolean) => {
    triggerReloadTestsTable()
    setIsAddPanelistModalOpened(false)
    if (redirect) navigate(routes.sideBySideProject(project.id))
  }

  return (
    !!project && (
      <div className="side-by-side-project-details-layout">
        <div className="side-by-side-project-details-layout__header">
          <div className="side-by-side-project-details-layout__header__left">
            <div
              onClick={() => navigate(routes.sideBySide)}
              className="side-by-side-project-details-layout__header__go-back"
            >
              <DatacIcon
                className="side-by-side-project-details-layout__go-back-icon"
                name="arrowLeft"
                type="blue"
                size="small"
              />
              {intlHeader('back')}
            </div>
            <div className="side-by-side-project-details-layout__header__name">
              <DatacTitle type="h1">{project.name}</DatacTitle>
              <DatacStudyStatusTag status={project.status} />
            </div>
          </div>
          <Button
            type="default"
            size="large"
            onClick={() => setIsSelectFormModalOpened(true)}
            className="side-by-side-project-details-layout__header__button side-by-side-project-details-layout__header__button--white"
          >
            <DatacIcon type="blue" name="plus" size="big" />
            {intlFormula('add')}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsAddPanelistModalOpened(true)}
            className="side-by-side-project-details-layout__header__button"
          >
            <DatacIcon type="white-on-semi-transparent" name="plus" size="big" />
            {intlPanelists('title')}
          </Button>
        </div>
        <Tabs
          className="side-by-side-project-details-layout__tabs"
          activeKey={currentTab}
          size="large"
          onTabClick={tab => changeTab(tab as SideBySideProjectDetailsTab)}
        >
          <Tabs.TabPane key={SideBySideProjectDetailsTab.Tests} tab={intlHeader('tests')}>
            {currentTab === SideBySideProjectDetailsTab.Tests && children}
          </Tabs.TabPane>

          <Tabs.TabPane key={SideBySideProjectDetailsTab.Reports} tab={intlHeader('reports')}>
            {currentTab === SideBySideProjectDetailsTab.Reports && children}
          </Tabs.TabPane>

          <Tabs.TabPane key={SideBySideProjectDetailsTab.Products} tab={intlHeader('products')}>
            {currentTab === SideBySideProjectDetailsTab.Products && children}
          </Tabs.TabPane>

          <Tabs.TabPane key={SideBySideProjectDetailsTab.Settings} tab={intlHeader('settings')}>
            {currentTab === SideBySideProjectDetailsTab.Settings && children}
          </Tabs.TabPane>
        </Tabs>
        <AddPanelistModal onClose={onNewSubjectModalClose} isOpened={isAddPanelistModalOpened} />
        <AddProductModal onClose={onNewProductModalClose} isOpened={isAddProductModalOpened} />
        <AssignProductsModal onClose={onAssignProductsModalClose} isOpened={isAssignProductsModalOpened} />
        <SelectFormModal onClose={onSelectFormModalClose} isOpened={isSelectFormModalOpened} />
      </div>
    )
  )
}
