import './TestFormNavigation.less'

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { Section, Structure } from '../../../../../../requests'
import { DatacIcon, DatacRecordStatusIndicator, DatacRecordStatusLabel, DatacTooltip } from '../../../../../common'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  structure: Structure
  onSubsectionChange: (subsectionId: string) => void
}

const contextProps: PropsFromContextKeys = ['record', 'sectionId', 'subsectionId']

const NavigationInner: React.FC<Props & PropsFromContext> = ({
  structure,
  onSubsectionChange,
  record,
  sectionId,
  subsectionId
}) => {
  const [openedSectionId, setOpenedSectionId] = useState<string>(null)

  useEffect(() => {
    setOpenedSectionId(sectionId)
  }, [sectionId])

  const toggleSectionOpen = (section: Section) => {
    if (openedSectionId === section.id) {
      setOpenedSectionId(null)
      return
    }

    setOpenedSectionId(section.id)
    if (sectionId !== section.id) onSubsectionChange(section.subsections[0].id)
  }

  const onSectionClick = (section: Section) => {
    if (openedSectionId !== section.id) toggleSectionOpen(section)
    else onSubsectionChange(section.subsections[0].id)
  }

  return (
    <div className={classNames('test-form-navigation')}>
      {structure.sections.map(section => {
        const sectionAdvancement = record?.sectionsAdvancement?.[section.id]
        const sectionStatus = sectionAdvancement?.status

        return (
          <div
            className={classNames('test-form-navigation__section', {
              'test-form-navigation__section--active': section.id === sectionId
            })}
            key={section.id}
          >
            <div className="test-form-navigation__section-title">
              <div className="test-form-navigation__section-title__name" onClick={() => onSectionClick(section)}>
                <DatacTooltip title={section.name}>{section.name} </DatacTooltip>
              </div>
              <DatacIcon
                size="small"
                name={openedSectionId === section.id ? 'chevronUp' : 'chevronDown'}
                type="blue"
                onClick={() => toggleSectionOpen(section)}
              />
            </div>
            <div className="test-form-navigation__section-meta">
              <DatacRecordStatusIndicator status={sectionStatus} />
              <DatacRecordStatusLabel className="test-form-navigation__section-status" status={sectionStatus} />
            </div>

            {openedSectionId === section.id && (
              <div className="test-form-navigation__subsections">
                {section.subsections.map(subsection => {
                  const subsectionAdvancement = record?.sectionsAdvancement[section.id]?.subsections?.[subsection.id]
                  const subsectionStatus = subsectionAdvancement?.status

                  return (
                    <div
                      key={subsection.id}
                      className={classNames('test-form-navigation__subsection', {
                        'test-form-navigation__subsection--active': subsection.id === subsectionId
                      })}
                    >
                      <DatacRecordStatusIndicator status={subsectionStatus} tooltip />
                      <DatacTooltip title={subsection.name}>
                        <div
                          className="test-form-navigation__subsection-name"
                          onClick={() => onSubsectionChange(subsection.id)}
                        >
                          {subsection.name}
                        </div>
                      </DatacTooltip>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export const Navigation = TestFormContextWrapper<Props>(NavigationInner, contextProps)
