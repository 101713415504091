import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Product, ProjectCategory, fetchBrands, fetchProjectProducts } from '../../../../../../requests'
import { DatacIcon, DatacMessage, DatacOption } from '../../../../../common'
import { ProductsTable } from '../../../../../shared/ProductsTable'
import { useSideBySideProjectDetailsStore } from '../../../SideBySideProjectDetailsStore'
import { EditProductModal } from '../../../SideBySideProjectProducts/EditProductModal'

const pageSize = 25
export const maxProducts = 8

const ActionCell: React.FC<{
  product: Product
  onDuplicate: (product: Product) => void
  onAdd: (product: Product) => void
  selectedProducts: Product[]
}> = ({ product, onDuplicate, onAdd, selectedProducts }) => {
  const idDisabled = selectedProducts?.length >= maxProducts || selectedProducts?.map(p => p.id).includes(product.id)
  return (
    <div className="test-zones__product-actions">
      <DatacIcon
        name="copy"
        type="blue"
        className="test-zones__product-actions__duplicate"
        onClick={() => onDuplicate(product)}
      />

      <DatacIcon
        name="plus"
        type="white"
        className={idDisabled ? 'test-zones__product-actions__disabled' : 'test-zones__product-actions__add'}
        onClick={() => !idDisabled && onAdd(product)}
      />
    </div>
  )
}

interface TestZonesProductsProps {
  onAddProduct: (product: Product) => void
  selectedProducts: Product[]
}

export const TestZonesProducts: React.FC<TestZonesProductsProps> = ({ onAddProduct, selectedProducts }) => {
  const intl = useScopedIntl('')
  const { project } = useSideBySideProjectDetailsStore()
  const [productForAction, setProductForAction] = useState<Product>(null)
  const [reloadTable, setReloadTable] = useState(false)

  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])

  useEffect(() => {
    updateBrandList()
  }, [])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const onDuplicate = (product: Product) => setProductForAction({ ...product, id: null })

  const onModalClose = () => {
    setProductForAction(null)
    setReloadTable(!reloadTable)
  }

  return (
    <div
      className={classNames('test-zones__products', {
        'test-zones__products--no-shade-search': project.category !== ProjectCategory.HairColor
      })}
    >
      <ProductsTable
        pageSize={pageSize}
        fetchProducts={fetchProjectProducts(project.id)}
        brandOptions={brandOptions}
        actionCell={product => (
          <ActionCell
            product={product}
            onDuplicate={onDuplicate}
            onAdd={onAddProduct}
            selectedProducts={selectedProducts}
          />
        )}
        reloadTable={reloadTable}
        isZoneSelection
        hasShadeSearch={project.category === ProjectCategory.HairColor}
        projectCategory={project.category}
      />
      <EditProductModal onClose={onModalClose} product={productForAction} />
    </div>
  )
}
