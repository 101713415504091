import { Column, Radar } from '@ant-design/plots'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { SideBySideChart, SideBySideChartType } from '../../../../../requests'
import { DatacIcon, DatacLoading, DatacTooltip } from '../../../../common'
import { getColumnConfig, getDummyColumnConfig, getRadarConfig } from '../chartConfig'

interface Props {
  title: string
  chart: SideBySideChart
  onRefresh: () => void
  isRefreshing: boolean
}

export const ChartPreview: React.FC<Props> = ({ title, chart, onRefresh, isRefreshing }) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')

  const header = (
    <div className="side_by_side-chart-wizard__preview__header">
      <h3>{title || intlChart('unnamed_chart')}</h3>
      <div
        className={classNames('side_by_side-chart-wizard__preview__header__refresh', {
          'side_by_side-chart-wizard__preview__header__refresh--hidden': !chart
        })}
      >
        <Button type="link" onClick={onRefresh} disabled={isRefreshing}>
          <DatacIcon name="refresh" raw />
          {intlChart('refresh')}
        </Button>
        <DatacTooltip title={chart?.lastRefresh?.format('DD MMM YYYY, HH:mm')}>
          <small>{intlChart('data_from', { time: chart?.lastRefresh?.fromNow() })}</small>
        </DatacTooltip>
      </div>
    </div>
  )

  if (!chart?.data) {
    return (
      <div className="side_by_side-chart-wizard__preview side_by_side-chart-wizard__preview--placeholder">
        <div className="side_by_side-chart-wizard__preview__placeholder-info">
          <strong>{intlChart('no_data')}</strong>
          <span>{intlChart('add_formulas_first')}</span>
        </div>
        {header}
        <Column {...getDummyColumnConfig()} />
      </div>
    )
  }
  return (
    <div className="side_by_side-chart-wizard__preview" data-type={chart?.type || SideBySideChartType.Column}>
      {header}
      <div className="side_by_side-chart-wizard__preview__content">
        <DatacLoading isLoading={isRefreshing} />
        {chart.type === SideBySideChartType.Column && (
          <Column {...getColumnConfig(chart.data, chart.formulas, chart.questions, chart.axis)} />
        )}
        {chart.type === SideBySideChartType.Radar && (
          <Radar {...getRadarConfig(chart.data, chart.formulas, chart.questions, chart.axis)} />
        )}
      </div>
      <div className="side_by_side-chart-wizard__preview__footer" />
    </div>
  )
}
