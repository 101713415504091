import './TestFormHeader.less'

import React, { useEffect, useRef } from 'react'

import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  header: string
  isGlobal: boolean
}

const contextProps: PropsFromContextKeys = ['record', 'columnWidth']

export const TestFormHeaderInner: React.FC<Props & PropsFromContext> = ({ isGlobal, header, record, columnWidth }) => {
  const globalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!globalRef.current || !wrapperRef.current || !header) return
    wrapperRef.current.style.height = `${globalRef.current.clientHeight}px`
  }, [globalRef.current, wrapperRef.current, header, columnWidth])

  return isGlobal ? (
    <div className="test-form-static-content-header__wrapper" ref={wrapperRef}>
      <div className="test-form-static-content-header__global" ref={globalRef}>
        <div className="test-form-static-content-header">
          <div className="test-form-static-content-header__block" style={{ width: '100%' }}>
            <h5 className="test-form-static-content-header__block__inner">{header}</h5>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="test-form-static-content-header">
      {record.products.map(product => (
        <div
          className="test-form-static-content-header__block"
          key={product.id}
          style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
        >
          <h5 className="test-form-static-content-header__block__inner">{header}</h5>
        </div>
      ))}
    </div>
  )
}

export const TestFormHeader = TestFormContextWrapper<Props>(TestFormHeaderInner, contextProps)
