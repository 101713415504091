import './DropdownQuestionControl.less'

import React, { useRef } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import { SelectQuestion } from '../../../../../../../requests'
import { DatacSelect } from '../../../../../../common'

interface Value {
  selected: string
}

interface Props {
  question: SelectQuestion
  onChange: (value: Value) => void
  value?: Value
  disabled?: boolean
  dropdownWidth?: 'match-control' | 'auto'
}

export const DropdownQuestionControl: React.FC<Props> = ({
  question,
  value,
  onChange,
  disabled = false,
  dropdownWidth = 'match-control'
}) => {
  const intlDropdown = useScopedIntl('studies.fulfillment.question.dropdown')
  const nativeSelectRef = useRef<HTMLSelectElement>(null)

  const onSelectionChange = (selected: string) => {
    onChange({ selected })
  }

  return (
    <div className="test-form-dropdown-question-control">
      {/* we need to use native dropdown list, so we use native html select. 
      to preserve styling we are hiding it behind antd component with disabled pointer events  */}
      <select
        ref={nativeSelectRef}
        className="test-form-dropdown-question-control__select"
        value={value?.selected || undefined}
        disabled={disabled}
        onChange={e => onSelectionChange(e.target.value)}
      >
        <option selected disabled>
          {intlDropdown('placeholder')}
        </option>
        {question.config.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <DatacSelect
        className="test-form-dropdown-question-control__antd-select"
        dropDownClassName={`datac-select__dropdown--${dropdownWidth}`}
        placeholder={intlDropdown('placeholder')}
        value={value?.selected || null}
        onChange={value => onSelectionChange(value)}
        options={question.config.options}
        disabled={disabled}
      />
    </div>
  )
}
