import './ProjectsFilters.less'

import { Button, Checkbox, Form, Popover } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { ProjectCategory, ProjectStatus } from '../../../../requests'
import { enumToOptions } from '../../../../utils'
import { DatacIcon } from '../../../common'

export interface Filters {
  status: ProjectStatus[]
  category: ProjectCategory[]
  brand: string[]
}

export const defaultFilters: Filters = {
  status: [],
  category: [],
  brand: []
}

export const ProjectsFilters: React.FC<{
  onFilter: (data: Filters) => void
  initialValues: Filters
  isActive: boolean
  brands: string[]
}> = ({ onFilter, initialValues, isActive, brands }) => {
  const intl = useScopedIntl('')
  const intlCategory = useScopedIntl('side_by_side.card.category')
  const intlStatus = useScopedIntl('studies.status')
  const intlField = useScopedIntl('side_by_side.projects.field')
  const [formInstance] = Form.useForm()
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    formInstance.setFieldsValue(initialValues)
  }, [initialValues])

  const onSubmit = () => formInstance.submit()

  const onClear = () => {
    formInstance.setFieldsValue(defaultFilters)
    onSubmit()
  }

  const onFormFinish = (values: Filters) => {
    setIsOpened(false)
    onFilter(values)
  }

  const popoverContent = (
    <div className="projects-filters__dropdown ">
      <div className="projects-filters__options">
        <Form.Item className="projects-filters__filter-options" name="status" label={`${intlField('status.label')}:`}>
          <Checkbox.Group options={enumToOptions(ProjectStatus, intlStatus)} />
        </Form.Item>
        <Form.Item
          className="projects-filters__filter-options"
          name="category"
          label={`${intlField('category.label')}:`}
        >
          <Checkbox.Group options={enumToOptions(ProjectCategory, intlCategory)} />
        </Form.Item>
        <Form.Item className="projects-filters__filter-options" name="brand" label={`${intlField('brand.label')}:`}>
          <Checkbox.Group options={brands.map(brand => ({ value: brand, label: brand }))} />
        </Form.Item>
      </div>
      <div className="projects-filters__controls">
        <Button size="middle" type="default" onClick={onClear}>
          {intl('common.clear')}
        </Button>
        <Button size="middle" type="primary" onClick={onSubmit}>
          {intl('common.save')}
        </Button>
      </div>
    </div>
  )

  return (
    <Form name="projects-filters-form" onFinish={onFormFinish} form={formInstance} initialValues={initialValues}>
      <Popover
        content={popoverContent}
        trigger="click"
        placement="bottomLeft"
        open={isOpened}
        onOpenChange={setIsOpened}
        defaultOpen={isActive}
      >
        <div className="projects-filters__toggle">
          {isActive && <div className="projects-filters__toggle-badge"> </div>}
          <DatacIcon name="filters" raw className="projects-filters__toggle-icon" />
        </div>
      </Popover>
    </Form>
  )
}
