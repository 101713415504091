import './EditProductModal.less'

import { Form, Input, InputNumber } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Product, createFormula, editFormula } from '../../../../../requests'
import { maxTitleLengthLong } from '../../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { ProductInfoRecord } from './ProductInfoRecord'

interface EditProductModalProps {
  onClose: () => void
  product: Product
}

export const EditProductModal: React.FC<EditProductModalProps> = ({ onClose, product }) => {
  const intlProducts = useScopedIntl('side_by_side.products.table')
  const intlFormula = useScopedIntl('side_by_side.products.formula')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const { project } = useSideBySideProjectDetailsStore()
  const [formula, setFormula] = useState(product?.formula)

  useEffect(() => {
    if (!product) return

    formInstance.setFieldsValue({
      technology: product.technology,
      status: product.status,
      shadeName: product.shadeName,
      batch: product.batch,
      devVol: product.devVol,
      mixRatio: product.mixRatio,
      quantity: product.quantity,
      processingTime: product.processingTime,
      specialInstructions: product.specialInstructions,
      specialApplication: product.specialApplication
    })

    setFormula(product.formula)
  }, [product])

  const onSubmit = (data: Partial<Product>) =>
    (product.id ? editFormula : createFormula)(
      { projectId: project.id, ...product, ...data, formula },
      {
        onSuccess: onClose,
        onProductAlreadyExists: () =>
          DatacMessage.error(
            intlFormula(`error.exists.title.${product.id ? 'update' : 'duplication'}`),
            intlFormula('error.exists.description')
          ),
        onProductAlreadyHasTests: () =>
          DatacMessage.error(intlFormula('error.exists.title.update'), intlFormula('error.has_tests.description')),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )

  return (
    <DatacModal
      className="edit-product-modal"
      isOpened={!!product}
      onClose={onClose}
      onSubmit={() => formInstance.submit()}
      title={intlFormula(product?.id ? 'edit' : 'duplicate')}
    >
      {/* usually form should be outside of modal,
      but in this case placing it inside don't create any issues.
      Placing it outside would create form inside form in test page, which is not allowed. */}
      <Form form={formInstance} name="editProductForm" onFinish={onSubmit}>
        <div className="edit-product-modal__content">
          {!product?.id && (
            <div className="edit-product-modal__formula">
              <ProductInfoRecord
                icon="productFormula"
                label="formula"
                value={formula}
                setValue={!product?.id && setFormula}
              />
            </div>
          )}
          <div className="edit-product-modal__wrapper">
            <div className="edit-product-modal__info">
              {product?.id && <ProductInfoRecord icon="productFormula" label="formula" value={formula} />}
              <ProductInfoRecord
                icon="productType"
                label="type"
                value={product?.type && intlStatus(product.type.toLowerCase())}
              />
              <ProductInfoRecord icon="productBrand" label="brand" value={product?.brand} />
              <ProductInfoRecord icon="productName" label="product_name" value={product?.productName} />
              <ProductInfoRecord icon="productCategory" label="category" value={product?.category} />
              <ProductInfoRecord icon="productTechnology" label="technology" value={product?.technology} />
              <ProductInfoRecord
                icon="checkCircle"
                label="status"
                value={product?.status && intlStatus(product?.status.toLowerCase())}
              />
            </div>
            <div
              className={classNames(
                'edit-product-modal__variations',
                !product?.id && 'edit-product-modal__variations--duplication'
              )}
            >
              <DatacFormItem name="shadeName" label={intlProducts('shade_name')}>
                <Input
                  size="large"
                  placeholder={intlProducts('shade_name.placeholder')}
                  maxLength={maxTitleLengthLong}
                />
              </DatacFormItem>
              <DatacFormItem name="batch" label={intlProducts('batch')}>
                <Input size="large" placeholder={intlProducts('batch.placeholder')} maxLength={maxTitleLengthLong} />
              </DatacFormItem>
              <DatacFormItem name="devVol" label={intlProducts('dev_vol')}>
                <Input size="large" placeholder={intlProducts('dev_vol.placeholder')} maxLength={maxTitleLengthLong} />
              </DatacFormItem>
              <DatacFormItem name="mixRatio" label={intlProducts('mix_ratio')}>
                <Input
                  size="large"
                  placeholder={intlProducts('mix_ratio.placeholder')}
                  maxLength={maxTitleLengthLong}
                />
              </DatacFormItem>
              <DatacFormItem name="quantity" label={intlProducts('quantity')}>
                <Input size="large" placeholder={intlProducts('quantity.placeholder')} maxLength={maxTitleLengthLong} />
              </DatacFormItem>
              <DatacFormItem name="processingTime" label={intlProducts('processing_time')}>
                <InputNumber size="large" placeholder={intlProducts('processing_time.placeholder')} min={0} />
              </DatacFormItem>
              <DatacFormItem name="specialInstructions" label={intlProducts('special_instructions')}>
                <Input
                  size="large"
                  placeholder={intlProducts('special_instructions.placeholder')}
                  maxLength={maxTitleLengthLong}
                />
              </DatacFormItem>
              <DatacFormItem name="specialApplication" label={intlProducts('special_application')}>
                <Input
                  size="large"
                  placeholder={intlProducts('special_application.placeholder')}
                  maxLength={maxTitleLengthLong}
                />
              </DatacFormItem>
            </div>
          </div>
        </div>
      </Form>
    </DatacModal>
  )
}
