import './TestFormImage.less'

import { Image } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { FileState, fetchFileUrls } from '../../../../../../requests'
import { proxyMinioToLocalhost } from '../../../../../../utils'
import { DatacImagePlaceholder } from '../../../../../common'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  fileId: string
  isGlobal: boolean
}

const contextProps: PropsFromContextKeys = ['record', 'columnWidth']

export const TestFormImageInner: React.FC<Props & PropsFromContext> = ({ isGlobal, fileId, record, columnWidth }) => {
  const [state, setState] = useState<FileState>(FileState.Preloading)
  const [url, setUrl] = useState<string>(undefined)
  const globalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!globalRef.current || !wrapperRef.current || !fileId) return undefined

    const resizeObserver = new ResizeObserver(() => {
      wrapperRef.current.style.height = `${globalRef.current.clientHeight}px`
    })
    resizeObserver.observe(globalRef.current)

    return () => resizeObserver.disconnect()
  }, [globalRef.current, wrapperRef.current])

  useEffect(() => {
    setState(FileState.Preloading)
    fetchFileUrls(
      {
        fileIds: [fileId]
      },
      {
        onSuccess: fileUrls => {
          setState(FileState.Ready)
          setUrl(fileUrls[fileId] ? proxyMinioToLocalhost(fileUrls[fileId]) : undefined)
        },
        onRequestError: () => {
          setState(FileState.Error)
          setUrl(undefined)
        }
      }
    )
  }, [fileId])

  return isGlobal ? (
    <div className="test-form-static-content-image__wrapper" ref={wrapperRef}>
      <div className="test-form-static-content-image__global" ref={globalRef}>
        <div className="test-form-static-content-image">
          <div className="test-form-static-content-image__block" style={{ width: '100%' }}>
            <Image
              className="test-form-static-content-image__block__image"
              src={url}
              placeholder={<DatacImagePlaceholder state={state} />}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="test-form-static-content-image">
      {record.products.map(product => (
        <div
          className="test-form-static-content-image__block"
          key={product.id}
          style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
        >
          <Image
            className="test-form-static-content-image__block__image"
            src={url}
            placeholder={<DatacImagePlaceholder state={state} />}
          />
        </div>
      ))}
    </div>
  )
}

export const TestFormImage = TestFormContextWrapper<Props>(TestFormImageInner, contextProps)
