import './SelectFormModal.less'

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import AssignImage from '../../../../../assets/images/add_formula_assign.svg'
import NewImage from '../../../../../assets/images/add_formula_new.svg'
import { useScopedIntl } from '../../../../../hooks'
import { DatacIcon, DatacModal, DatacTitle } from '../../../../common'
import { NewProductFormType } from '../SideBySideProjectDetailsLayout'

interface SelectFormModalProps {
  onClose: (next?: NewProductFormType) => void
  isOpened: boolean
}

export const SelectFormModal: React.FC<SelectFormModalProps> = ({ onClose, isOpened }) => {
  const intlFormula = useScopedIntl('side_by_side.products.formula')
  const intl = useScopedIntl('')
  const [newProductFormType, setNewProductFormType] = useState(NewProductFormType.Add)

  useEffect(() => {
    if (isOpened) setNewProductFormType(NewProductFormType.Add)
  }, [isOpened])

  return (
    <DatacModal
      className="select-product-form-modal"
      isOpened={isOpened}
      onClose={() => onClose()}
      onSubmit={() => onClose(newProductFormType)}
      title={intlFormula('add')}
      submitLabel={intl('common.next')}
    >
      <div className="select-product-form-modal__content">
        <div
          className={classNames('select-product-form-modal__content__item', {
            'select-product-form-modal__content__item--selected': newProductFormType === NewProductFormType.Add
          })}
          onClick={() => setNewProductFormType(NewProductFormType.Add)}
        >
          <div className="select-product-form-modal__content__item__image">
            <NewImage />
            {newProductFormType === NewProductFormType.Add && <DatacIcon name="checkCircle" type="white" />}
          </div>
          <div className="select-product-form-modal__content__item__info">
            <DatacTitle size="small">{intlFormula('new.add.title')}</DatacTitle>
            <span>{intlFormula('new.add.description')}</span>
          </div>
        </div>
        <div
          className={classNames('select-product-form-modal__content__item', {
            'select-product-form-modal__content__item--selected': newProductFormType === NewProductFormType.Assign
          })}
          onClick={() => setNewProductFormType(NewProductFormType.Assign)}
        >
          <div className="select-product-form-modal__content__item__image">
            <AssignImage />
            {newProductFormType === NewProductFormType.Assign && <DatacIcon name="checkCircle" type="white" />}
          </div>
          <div className="select-product-form-modal__content__item__info">
            <DatacTitle size="small">{intlFormula('new.assign.title')}</DatacTitle>
            <span>{intlFormula('new.assign.description')}</span>
          </div>
        </div>
      </div>
    </DatacModal>
  )
}
