import './NewProject.less'

import { Button, Form, Input, RefSelectProps } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CreateProjectOptions, ProjectCategory, SorterOrder, createProject, fetchCenters } from '../../../../requests'
import { routes } from '../../../../routes'
import { enumToOptions } from '../../../../utils'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'

export const NewProject: React.FC<{ brands: string[] }> = ({ brands }) => {
  const intlProjects = useScopedIntl('side_by_side.projects')
  const intlCategory = useScopedIntl('side_by_side.card.category')
  const intlField = useScopedIntl('side_by_side.projects.field')
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const intl = useScopedIntl('')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [formInstance] = Form.useForm()
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])
  const [currentEnteredBrand, setCurrentEnteredBrand] = useState('')
  const [brand, setBrand] = useState('')
  const brandRef = useRef<RefSelectProps>()

  useEffect(() => {
    if (!isModalOpened) return

    updateCentersList()
    setBrand('')
    setCurrentEnteredBrand('')
    formInstance.resetFields()
  }, [isModalOpened])

  useEffect(() => {
    setBrandOptions(currentBrands => [...currentBrands, ...brands.map(brand => ({ value: brand, label: brand }))])
  }, [brands])

  const updateCentersList = () => {
    fetchCenters(
      { assignedToUser: true, options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } } },
      {
        onSuccess: ({ centers }) => {
          setCentersOptions(
            centers.map(center => ({
              value: center.id,
              label: center.abbreviation
            }))
          )
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onSubmit = (data: CreateProjectOptions) =>
    createProject(data, {
      onSuccess: ({ uuid }) => navigate(routes.sideBySideProject(uuid)),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })

  const onBrandSearch = (searchPhrase: string) => {
    setCurrentEnteredBrand(searchPhrase.substring(0, maxTitleLengthLong))
  }

  const setNewBrand = (brand: string) => {
    formInstance.setFieldsValue({ brand })
    setBrand(brand)
  }

  const onBrandKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredBrand.trim() ||
      brandOptions.find(({ value }) => value === currentEnteredBrand)
    ) {
      return
    }

    setBrandOptions(currentAvailableBrands => [
      ...currentAvailableBrands,
      { value: currentEnteredBrand, label: currentEnteredBrand }
    ])
    setNewBrand(currentEnteredBrand)
    onBrandSearch('')
    setCurrentEnteredBrand('')
    brandRef.current.blur()
  }

  const onBrandSelect = (selectedBrand: string) => {
    setNewBrand(brand === selectedBrand ? '' : selectedBrand)
  }

  return (
    <>
      <Button className="new-project__button" type="primary" onClick={() => setIsModalOpened(true)} size="large">
        <DatacIcon name="plus" type="white" size="big" /> {intlProjects('create_new')}
      </Button>
      <Form form={formInstance} name="newStudyForm" layout="vertical" onFinish={onSubmit}>
        <DatacModal
          className="new-project__modal"
          isOpened={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          onSubmit={() => formInstance.submit()}
          title={intlProjects('create_new')}
        >
          <div className="new-project-form">
            <DatacFormItem
              name="name"
              label={intlField('name.label')}
              validate={validateRequired(intl('common.required'))}
            >
              <Input size="large" placeholder={intlField('name.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>

            <DatacFormItem
              name="centers"
              label={intlField('center.label')}
              validate={validateRequired(intl('common.required'))}
            >
              <DatacSelect
                showSearch
                mode="multiple"
                options={centersOptions}
                placeholder={intlField('center.placeholder')}
              />
            </DatacFormItem>

            <DatacFormItem
              name="brand"
              label={intlField('brand.label')}
              validate={validateRequired(intl('common.required'))}
            >
              <DatacSelect
                size="large"
                placeholder={intlField('brand.placeholder')}
                showSearch
                options={brandOptions}
                onInputKeyDown={onBrandKeyPressed}
                onSearch={onBrandSearch}
                defaultActiveFirstOption={false}
                selectRef={brandRef}
                onSelect={onBrandSelect}
                searchValue={currentEnteredBrand}
              />
            </DatacFormItem>
            <DatacFormItem
              name="category"
              label={intlField('category.label')}
              validate={validateRequired(intl('common.required'))}
            >
              <DatacSelect
                showSearch
                options={enumToOptions(ProjectCategory, intlCategory)}
                placeholder={intlField('category.placeholder')}
              />
            </DatacFormItem>
          </div>
        </DatacModal>
      </Form>
    </>
  )
}
