import './TestFormSubsection.less'

import { ContentState } from 'draft-js'
import React, { useEffect } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Block, BlockType, StaticContentType, Subsection } from '../../../../../../requests'
import { TestFormHeader } from '../TestFormHeader'
import { TestFormImage } from '../TestFormImage'
import { TestFormInstruction } from '../TestFormInstruction'
import { TestFormQuestion } from '../TestFormQuestion'
import { TestFormAction } from '../TestFormReducer'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  subsection: Subsection
}

const contextProps: PropsFromContextKeys = ['testFormDispatch', 'isReadyToShow']

const TestFormSubsectionInner: React.FC<Props & PropsFromContext> = ({
  subsection,
  testFormDispatch,
  isReadyToShow
}) => {
  const intlSubsection = useScopedIntl('studies.fulfillment.subsection')

  useEffect(() => {
    testFormDispatch({
      type: TestFormAction.SET_REQUIRED_QUESTION_ALERT,
      requiredQuestionsAlert: false
    })
  }, [subsection.id])

  const renderBlock = (block: Block) => {
    if (block.blockType === BlockType.Question) {
      return <TestFormQuestion question={block} />
    }

    if (block.blockType === BlockType.StaticContent && block.type === StaticContentType.Instruction) {
      return <TestFormInstruction isGlobal={block.isGlobal} instruction={block.config.instruction as ContentState} />
    }

    if (block.blockType === BlockType.StaticContent && block.type === StaticContentType.Header) {
      return <TestFormHeader isGlobal={block.isGlobal} header={block.config.header} />
    }

    if (block.blockType === BlockType.StaticContent && block.type === StaticContentType.Image) {
      return <TestFormImage isGlobal={block.isGlobal} fileId={block.config.fileId} />
    }

    return null
  }

  const renderBlocks = () =>
    subsection.blocks.map(block => (
      <li key={block.id} className="test-form-subsection__block">
        {renderBlock(block)}
      </li>
    ))

  return (
    isReadyToShow && (
      <div className="test-form-subsection">
        {subsection.blocks?.length ? (
          <ul className="test-form-subsection__questions-list">{renderBlocks()}</ul>
        ) : (
          <div className="test-form-subsection__no-questions">{intlSubsection('no_questions')}</div>
        )}
      </div>
    )
  )
}

export const TestFormSubsection = TestFormContextWrapper<Props>(TestFormSubsectionInner, contextProps)
