import { Card, Popover } from 'antd'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Project } from '../../../../requests'
import { DatacIcon, DatacInfoRecord, DatacStudyStatusTag } from '../../../common'

export const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  const intlCard = useScopedIntl('side_by_side.card')
  const intlMore = useScopedIntl('side_by_side.card.more')

  const renderCenters = () => {
    if (!project.centers?.length) return '-'

    const centersNamesList = project.centers.map(({ abbreviation, name }) => (
      <div className="projects-list-card__center" key={abbreviation}>
        {name}
      </div>
    ))

    if (centersNamesList.length === 1) return <>{centersNamesList}</>

    const popoverContent = <div className="projects-list-card__centers">{centersNamesList}</div>

    return (
      <>
        <span className="projects-list-card__first_centers">{centersNamesList[0]}</span>
        <Popover content={popoverContent} placement="bottomLeft" trigger="hover">
          + {project.centers.length - 1} {project.centers.length > 2 ? intlMore('centers') : intlMore('center')}
        </Popover>
      </>
    )
  }

  const categoryValue = project.category ? intlCard(`category.${project.category.toLowerCase()}`) : '-'

  return (
    <Card
      title={
        <div className="project-details__header">
          <div className="project-details__header__top">
            <DatacStudyStatusTag status={project.status} />
            <DatacIcon type="white" name="arrowRight" size="big" />
          </div>
          <span className="project-details__header__name">{project.name}</span>
        </div>
      }
      className="studies-list__project-details project-details"
    >
      <div className="project-details__info">
        <DatacInfoRecord isProjectRecord icon="tag" label={intlCard('brand')} value={project.brand} />
        <DatacInfoRecord isProjectRecord icon="box" label={intlCard('category')} value={categoryValue} />
        <DatacInfoRecord isProjectRecord icon="disc" label={intlCard('center')} value={renderCenters()} />
      </div>
    </Card>
  )
}
