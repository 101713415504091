import './TextQuestionControl.less'

import { Input } from 'antd'
import React from 'react'

import { TextQuestion } from '../../../../../../../requests'

interface Props {
  question: TextQuestion
  onChange: (value: string) => void
  value?: string
  disabled?: boolean
}

export const TextQuestionControl: React.FC<Props> = ({ question, onChange, value, disabled = false }) => {
  return disabled ? (
    <Input.TextArea className="test-form-text-question-control" value={value} disabled={disabled} autoSize />
  ) : (
    <Input
      size="large"
      placeholder={(question as TextQuestion).config?.placeholder}
      onChange={e => onChange(e.target.value || '')}
      value={value}
      maxLength={(question as TextQuestion).config?.maxCharacters}
    />
  )
}
