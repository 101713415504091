import './SideBySideProjectSettingsLayout.less'

import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { routes } from '../../../../../routes'
import { DatacMenuOption, DatacTitle } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

export const SideBySideProjectSettingsLayout: React.FC = ({ children }) => {
  const intlMenu = useScopedIntl('side_by_side.project.settings.menu')
  const { project } = useSideBySideProjectDetailsStore()

  return (
    <div className="side-by-side-project-settings-layout">
      <div className="side-by-side-project-settings-layout__menu">
        <div className="side-by-side-project-settings-layout__menu__box">
          <DatacTitle size="small">{intlMenu('main')}</DatacTitle>
          <DatacMenuOption
            route={routes.sideBySideProjectSettings(project.id)}
            label={intlMenu('general')}
            iconName="settings"
            exactMatch
          />

          <DatacMenuOption
            route={routes.sideBySideProjectSettingsUsers(project.id)}
            label={intlMenu('users')}
            iconName="users"
          />

          <DatacMenuOption
            route={routes.sideBySideProjectBuilder(project.id)}
            label={intlMenu('builder')}
            iconName="builder"
          />

          <DatacMenuOption
            route={routes.sideBySideProjectSettingsPanelists(project.id)}
            label={intlMenu('panelists')}
            iconName="users"
          />
        </div>
      </div>
      <main className="side-by-side-project-settings-layout__content">{children}</main>
    </div>
  )
}
