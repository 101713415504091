import './SideBySideProjectTestPanelist.less'

import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  FileState,
  RecordStatus,
  SideBySideTest,
  downloadSubjectRepositoryFileAnswer,
  fetchFileUrls
} from '../../../../../requests'
import { proxyMinioToLocalhost } from '../../../../../utils'
import { DatacFileDownloadPreview, DatacIcon } from '../../../../common'

interface Props {
  test: SideBySideTest
  setTest: (test: SideBySideTest) => void
}

export const SideBySideProjectTestPanelist: React.FC<Props> = ({ test, setTest }) => {
  const intlTest = useScopedIntl('side_by_side.project.test')
  const [state, setState] = useState<FileState>(FileState.Preloading)
  const [url, setUrl] = useState<string>(undefined)
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    if (!test?.imageId) return

    fetchFileUrls(
      {
        fileIds: [test.imageId]
      },
      {
        onSuccess: fileUrls => {
          setState(FileState.Ready)
          // todo: use thumbnail
          setUrl(fileUrls[test.imageId] ? proxyMinioToLocalhost(fileUrls[test.imageId]) : undefined)
        },
        onRequestError: () => {
          setState(FileState.Error)
          setUrl(undefined)
        }
      }
    )
  }, [test?.imageId])

  const onZonesClick = () => setTest({ ...test, isFormStarted: false })

  return (
    <div className="test-panelist">
      <div className="test-panelist__row">
        <div>
          <div className="test-panelist__label">{intlTest('panelist_information')}</div>
          <div className="test-panelist__value">
            {test?.firstName} {test?.lastName}
          </div>
        </div>
        <DatacIcon
          size="small"
          name={isCollapsed ? 'chevronDown' : 'chevronUp'}
          type="blue"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>

      <div className="test-panelist__row">
        <div className="test-panelist__image" style={url && { backgroundImage: `url(${url})` }}>
          {(!test?.imageId || state === FileState.Error) && (
            <div className="test-panelist__image__initials ">
              {test?.firstName?.[0]}
              {test?.lastName?.[0]}
            </div>
          )}
        </div>
        {test?.isFormStarted && test?.status !== RecordStatus.Ended && (
          <div className="test-panelist__zones" onClick={onZonesClick}>
            <DatacIcon name="edit" type="blue" size="small" />
            {intlTest('zones.edit')}
          </div>
        )}
      </div>

      {!isCollapsed && (
        <div className="test-panelist__information">
          {test?.informationAnswers.map((a, index) => (
            <div className="test-panelist__record" key={index}>
              <div className="test-panelist__label">
                <Popover content={a.question} trigger={a.question?.length > 10 ? 'hover' : null}>
                  {a.question}
                </Popover>
              </div>
              <div className="test-panelist__value">
                {a.answer.isFile && (
                  <DatacFileDownloadPreview
                    filename={a.answer.name}
                    fileId={a.answer.uuid}
                    downloadFile={downloadSubjectRepositoryFileAnswer}
                  />
                )}
                {a.answer.isFile === false && (
                  <Popover content={a.answer.value} trigger={a.answer.value?.length > 10 ? 'hover' : null}>
                    {a.answer.value}
                  </Popover>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
