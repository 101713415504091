import { Button, Checkbox, Drawer, Input } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { DatacIcon, DatacOption } from '../../../../common'
import { DrawerSection } from './ChartWizard'

interface Props {
  type: DrawerSection
  open: boolean
  options: DatacOption[]
  selected: DatacOption[]
  total: number
  onClose: () => void
  onSearch: (search: string) => void
  setSelected: (selected: DatacOption[]) => void
}

export const ListDrawer: React.FC<Props> = ({
  type,
  open,
  options,
  selected,
  total,
  onClose,
  onSearch,
  setSelected,
  children
}) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const [searchVal, setSearchVal] = useState('')

  const onSearchChange = useCallback(
    debounce((val: string) => {
      onSearch(val)
    }, 700),
    []
  )

  const onSearchClear = () => {
    setSearchVal('')
    onSearch('')
  }

  const toggleAll = () => {
    setSelected(selected === null ? [] : null)
  }

  const onChange = (val: string[]) => {
    setSelected(
      val.length === total
        ? null
        : val.map(v => {
            return options.find(option => option.value === `${v}`)
          })
    )
  }

  return (
    <Drawer
      open={open}
      placement="left"
      getContainer={false}
      onClose={onClose}
      className="side_by_side-chart-wizard__drawer"
      width="min-content"
    >
      <div className="side_by_side-chart-wizard__drawer__header">
        <strong>{intlChart(`field.${type.toLowerCase()}.label`)}</strong>
        <DatacIcon name="chevronLeft" onClick={onClose} />
      </div>
      <div className="side_by_side-chart-wizard__drawer__search">
        {searchVal && (
          <Button onClick={onSearchClear}>
            <DatacIcon name="x" />
          </Button>
        )}
        <Input
          onChange={e => {
            setSearchVal(e.currentTarget.value)
            onSearchChange(e.currentTarget.value)
          }}
          value={searchVal}
          placeholder={intlChart(`${type.toLowerCase()}.search.placeholder`)}
        />
      </div>
      {children}
      <Checkbox
        className={classNames('ant-checkbox-group-item', 'side_by_side-chart-wizard__drawer__select-all', {
          'side_by_side-chart-wizard__drawer__select-all--hidden': searchVal
        })}
        checked={selected === null || (selected.length === total && total > 0)}
        onChange={toggleAll}
      >
        {intlChart('select_deselect_all')}
      </Checkbox>
      <Checkbox.Group
        onChange={onChange}
        value={selected === null ? options.map(option => `${option.value}`) : selected.map(option => `${option.value}`)}
        className={`side_by_side-chart-wizard__drawer__list side_by_side-chart-wizard__drawer__list--${type.toLowerCase()}`}
      >
        {options.map(option => (
          <Checkbox className="ant-checkbox-group-item" value={option.value} key={option.value}>
            <strong>{option.label}</strong>
            <span className="side_by_side-chart-wizard__drawer__badge">{option.sublabel}</span>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Drawer>
  )
}
