import './ChartWizard.less'

import { Button, Form, Input, Radio, Switch } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  Panelist,
  Product,
  QuestionType,
  SideBySideChart,
  SideBySideChartFormula,
  SideBySideChartType,
  createSideBySideChart,
  fetchPanelists as fetchPanelistsRequest,
  fetchProjectProducts,
  previewSideBySideChart,
  searchProjectQuestions,
  updateSideBySideChart
} from '../../../../../requests'
import { UserContext } from '../../../../auth'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { ChartPreview } from './ChartPreview'
import { ChartWizardHeader } from './ChartWizardHeader'
import { ChartWizardSection } from './ChartWizardSection'
import { FormulasEditor } from './FormulasEditor'
import { FormulasTableDrawer } from './FormulasTableDrawer'
import { ListDrawer } from './ListDrawer'

const maxTitleLengthLong = 250

export enum DrawerSection {
  Formulas = 'FORMULAS',
  Questions = 'QUESTIONS',
  Panelists = 'PANELISTS'
}

const questionsToOptions = (questions: { id: string; title: string; variable: string }[]) =>
  questions
    ? questions.map(question => ({
        value: question.id,
        label: question.title,
        sublabel: question.variable
      }))
    : null

const panelistsToOptions = (panelists: Panelist[]) =>
  panelists
    ? panelists.map(panelist => ({
        value: `${panelist.id}`,
        label: panelist.fullName,
        sublabel: panelist.datacaptId
      }))
    : null

interface Props {
  onClose: (reload?: boolean) => void
  chart: SideBySideChart
}

export const ChartWizard: React.FC<Props> = ({ onClose, chart }) => {
  const intl = useScopedIntl('')
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const [isFetchingFormulas, setIsFetchingFormulas] = useState(false)
  const [isFetchingQuestions, setIsFetchingQuestions] = useState(false)
  const [isFetchingPanelists, setIsFetchingPanelists] = useState(false)
  const [selectedType, setSelectedType] = useState<SideBySideChartType>(null)
  const [drawerSection, setDrawerSection] = useState<DrawerSection>(null)
  const [allFormulas, setAllFormulas] = useState<Product[]>([])
  const [allFormulasCount, setAllFormulasCount] = useState(0)
  const [selectedFormulas, setSelectedFormulas] = useState<SideBySideChartFormula[]>(chart ? chart.formulas : [])
  const [allQuestions, setAllQuestions] = useState<DatacOption[]>([])
  const [allQuestionsCount, setAllQuestionsCount] = useState(0)
  const [selectedQuestions, setSelectedQuestions] = useState<DatacOption[]>(questionsToOptions(chart?.questions))
  const [allPanelists, setAllPanelists] = useState<DatacOption[]>([])
  const [allPanelistsCount, setAllPanelistsCount] = useState(0)
  const [selectedPanelists, setSelectedPanelists] = useState<DatacOption[]>(panelistsToOptions(chart?.panelists))
  const [panelistSearch, setPanelistSearch] = useState(null)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [previewChart, setPreviewChart] = useState<SideBySideChart>(chart)
  const [allTested, setAllTested] = useState(chart ? chart.allTested : true)
  const [title, setTitle] = useState(chart?.title)
  const [isCloseConfirmVisible, setIsCloseConfirmVisible] = useState(false)
  const isDirty = useRef<boolean>(chart ? null : false)
  const [formInstance] = Form.useForm()
  const { project } = useSideBySideProjectDetailsStore()
  const { user } = useContext(UserContext)

  useEffect(() => {
    fetchFormulas()
    fetchQuestions()
    fetchPanelists()
  }, [])

  useEffect(() => {
    fetchPanelists()
  }, [allTested, panelistSearch])

  useEffect(() => {
    if (!chart) {
      setSelectedType(SideBySideChartType.Column)
      formInstance.setFieldsValue({
        type: SideBySideChartType.Column
      })
      return
    }
    formInstance.setFieldsValue({
      title: chart.title,
      type: chart.type
    })
    setSelectedType(chart.type)
    setSelectedFormulas(chart.formulas)
    setSelectedQuestions(questionsToOptions(chart.questions))
    setSelectedPanelists(panelistsToOptions(chart.panelists))
  }, [chart])

  const fetchFormulas = (search?: string) => {
    setIsFetchingFormulas(true)
    fetchProjectProducts(project.id)(
      {
        search
      },
      {
        onSuccess: ({ products, allProductsCount }) => {
          setAllFormulas(products.map(product => ({ ...product, key: product.id })))
          if (!search) setAllFormulasCount(allProductsCount)
          setIsFetchingFormulas(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingFormulas(false)
        }
      }
    )
  }

  const setDirty = () => {
    if (isDirty.current === null) return
    isDirty.current = true
  }

  useEffect(() => {
    checkRadarQuestionCount(true)
    setDirty()
    onRefresh()
  }, [selectedType])

  useEffect(() => {
    setDirty()
  }, [selectedPanelists, selectedFormulas, selectedQuestions, title])

  const fetchQuestions = (search?: string) => {
    setIsFetchingQuestions(true)
    searchProjectQuestions(
      {
        search,
        projectId: project.id,
        studyId: null as string,
        limit: 999,
        types: [QuestionType.Dropdown, QuestionType.Number, QuestionType.Radio]
      },
      {
        onSuccess: questions => {
          if (!search) {
            setAllQuestionsCount(questions.length)
          }
          setAllQuestions(questionsToOptions(questions))
          setIsFetchingQuestions(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingQuestions(false)
        }
      }
    )
  }

  const fetchPanelists = () => {
    if (!selectedFormulas?.length) {
      setAllPanelists([])
      setAllPanelistsCount(0)
      return
    }
    setIsFetchingPanelists(true)
    fetchPanelistsRequest(
      {
        projectId: project.id,
        search: panelistSearch,
        formula: selectedFormulas.map(formula => formula.id),
        allTested
      },
      {
        onSuccess: ({ panelists, countAll }) => {
          setAllPanelists(panelistsToOptions(panelists))
          const newAllPanelistsCount = panelistSearch ? allPanelistsCount : countAll
          if (!panelistSearch) {
            setAllPanelistsCount(countAll)
            onRefresh()
          }
          if (selectedPanelists?.length) {
            const panelistsIds = panelists.map(panelist => `${panelist.id}`)
            const newSelectedPanelists = selectedPanelists.filter(panelist => panelistsIds.includes(panelist.value))
            setSelectedPanelists(newSelectedPanelists.length === newAllPanelistsCount ? null : newSelectedPanelists)
          }
          setIsFetchingPanelists(false)
          if (isDirty.current === null) {
            isDirty.current = false
          }
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingPanelists(false)
        }
      }
    )
  }

  const onTypeChange = (e: RadioChangeEvent) => {
    const chartType: SideBySideChartType = e.target.value
    setSelectedType(chartType)
  }

  const onFormulasClose = () => {
    toggleDrawer(DrawerSection.Formulas)
    fetchPanelists()
  }

  const onQuestionsClose = () => {
    toggleDrawer(DrawerSection.Questions)
    checkRadarQuestionCount(true)
    onRefresh()
  }

  const onPanelistsClose = () => {
    toggleDrawer(DrawerSection.Panelists)
    onRefresh()
  }

  const toggleDrawer = (section: DrawerSection, forceVal?: boolean) => {
    if (typeof forceVal !== 'undefined') {
      setDrawerSection(forceVal ? section : null)
      return
    }
    if (drawerSection === section) {
      setDrawerSection(null)
      return
    }
    setDrawerSection(section)
  }

  const onSearchFormulas = useCallback(
    debounce((search: string) => {
      fetchFormulas(search)
    }, 700),
    []
  )

  const onSearchQuestions = (search: string) => {
    fetchQuestions(search)
  }

  const onSearchPanelists = (search: string) => {
    setPanelistSearch(search)
  }

  const checkRadarQuestionCount = (showWarning?: boolean) => {
    if (
      selectedType === SideBySideChartType.Radar &&
      (selectedQuestions?.length || (selectedQuestions === null && allQuestionsCount)) < 3
    ) {
      if (showWarning)
        DatacMessage.warning(
          intlChart('radar_needs_3_questions_title'),
          intlChart('radar_needs_3_questions_description')
        )
      return false
    }
    return true
  }

  const canRefresh = () =>
    !!(
      !isRefreshing &&
      allPanelistsCount &&
      selectedFormulas?.length &&
      (selectedQuestions?.length || (selectedQuestions === null && allQuestions.length)) &&
      (selectedPanelists?.length || (selectedPanelists === null && allPanelistsCount)) &&
      checkRadarQuestionCount()
    )

  const onRefresh = () => {
    if (!canRefresh()) return
    setIsRefreshing(true)
    previewSideBySideChart(
      {
        projectId: project.id,
        data: getChartData(null, formInstance.getFieldValue('type'))
      },
      {
        onSuccess: freshChartData => {
          setIsRefreshing(false)
          setPreviewChart(freshChartData)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsRefreshing(false)
        }
      }
    )
  }

  const getChartData = (title: string, type: SideBySideChartType) => {
    return {
      title: title || intlChart('unnamed_chart'),
      type,
      formulas: selectedFormulas,
      questions:
        selectedQuestions?.map(question => ({
          id: `${question.value}`,
          title: `${question.label}`,
          variable: `${question.sublabel}`
        })) || null,
      panelists:
        selectedPanelists?.map(panelist => ({
          id: panelist.value as number,
          fullName: `${panelist.label}`,
          datacaptId: `${panelist.sublabel}`
        })) || null,
      allTested
    }
  }

  const onCloseClick = () => {
    if (isDirty.current) {
      setIsCloseConfirmVisible(true)
      return
    }
    onClose()
  }

  const onSaveClick = () => formInstance.submit()

  const onSaveChart = ({ title, type }: { title: string; type: SideBySideChartType }) => {
    const newChartData = getChartData(title, type)
    if (chart?.id) {
      updateSideBySideChart(
        {
          projectId: project.id,
          id: chart.id,
          data: newChartData
        },
        {
          onSuccess() {
            user.saveRecentColors(newChartData.formulas.map(formula => formula.color))
            onClose(true)
          }
        }
      )
      return
    }
    createSideBySideChart(
      {
        projectId: project.id,
        data: newChartData
      },
      {
        onSuccess() {
          user.saveRecentColors(newChartData.formulas.map(formula => formula.color))
          onClose(true)
        }
      }
    )
  }

  return (
    <Form onFinish={onSaveChart} form={formInstance}>
      <DatacModal
        title={intlChart('title')}
        isOpened
        noFooter
        noHeader
        fullScreen
        onClose={onCloseClick}
        className="side_by_side-chart-wizard__modal"
      >
        <div className="side_by_side-chart-wizard">
          <ChartWizardHeader onCancel={onCloseClick} onSave={onSaveClick} canSave={canRefresh()} />
          <div className="side_by_side-chart-wizard__sidebar">
            <DatacFormItem name="type">
              <Radio.Group
                onChange={onTypeChange}
                className={classNames('side_by_side-chart-wizard__sidebar__type', {
                  'side_by_side-chart-wizard__sidebar__type--radar': selectedType === SideBySideChartType.Radar
                })}
              >
                <Radio.Button value={SideBySideChartType.Column}>
                  <DatacIcon name="chartColumn" raw />
                  {intlChart('type_column')}
                </Radio.Button>
                <Radio.Button value={SideBySideChartType.Radar}>
                  <DatacIcon name="chartRadar" raw />
                  {intlChart('type_radar')}
                </Radio.Button>
              </Radio.Group>
            </DatacFormItem>
            <DatacFormItem name="title" label={intlChart('field.title.label')}>
              <Input
                maxLength={maxTitleLengthLong}
                placeholder={intlChart('field.title.placeholder')}
                onChange={e => setTitle(e.currentTarget.value)}
              />
            </DatacFormItem>
            <ChartWizardSection
              label={intlChart('field.formulas.label')}
              isActive={drawerSection === DrawerSection.Formulas}
              all={false}
              count={selectedFormulas.length}
              total={allFormulasCount}
              onClick={() => toggleDrawer(DrawerSection.Formulas)}
              isLoading={isFetchingFormulas}
            />
            {!selectedFormulas?.length && (
              <div className="side_by_side-chart-wizard__sidebar__no-formulas">
                <span>{intlChart('field.formulas.no_formulas')}</span>
                <Button type="primary" onClick={() => toggleDrawer(DrawerSection.Formulas)}>
                  {intlChart('field.formulas.add_new')}
                </Button>
              </div>
            )}
            {selectedFormulas?.length ? (
              <FormulasEditor
                formulas={selectedFormulas}
                onChange={setSelectedFormulas}
                openDrawer={() => toggleDrawer(DrawerSection.Formulas, true)}
              />
            ) : null}
            <ChartWizardSection
              label={intlChart('field.questions.label')}
              isActive={drawerSection === DrawerSection.Questions}
              all={allQuestionsCount && (selectedQuestions === null || selectedQuestions.length === allQuestionsCount)}
              count={selectedQuestions?.length}
              total={null}
              onClick={() => toggleDrawer(DrawerSection.Questions)}
              isLoading={isFetchingQuestions}
            />
            <ChartWizardSection
              label={intlChart('field.panelists.label')}
              isActive={drawerSection === DrawerSection.Panelists}
              all={selectedPanelists === null && !!allPanelistsCount}
              count={selectedPanelists?.length}
              total={allPanelistsCount}
              onClick={() => toggleDrawer(DrawerSection.Panelists)}
              isLoading={isFetchingPanelists}
            />
          </div>
          <div className="side_by_side-chart-wizard__content">
            <FormulasTableDrawer
              formulas={allFormulas}
              selectedFormulas={selectedFormulas}
              open={drawerSection === DrawerSection.Formulas}
              onClose={onFormulasClose}
              setSelectedFormulas={setSelectedFormulas}
              onSearch={onSearchFormulas}
            />
            <ListDrawer
              setSelected={selected => setSelectedQuestions(selected)}
              onClose={onQuestionsClose}
              onSearch={onSearchQuestions}
              options={allQuestions}
              selected={selectedQuestions}
              total={allQuestionsCount}
              type={DrawerSection.Questions}
              open={drawerSection === DrawerSection.Questions}
            />
            <ListDrawer
              setSelected={selected => setSelectedPanelists(selected)}
              onClose={onPanelistsClose}
              onSearch={onSearchPanelists}
              options={allPanelists}
              selected={selectedPanelists}
              total={allPanelistsCount}
              type={DrawerSection.Panelists}
              open={drawerSection === DrawerSection.Panelists}
            >
              <div className="side_by_side-chart-wizard__drawer__all-tested">
                <div>
                  <DatacIcon name="userCheck" raw />
                  {intlChart('panelists.all_tested')}
                </div>
                <Switch checked={allTested} onChange={setAllTested} />
              </div>
            </ListDrawer>
            <ChartPreview title={title} chart={previewChart} onRefresh={onRefresh} isRefreshing={isRefreshing} />
          </div>
        </div>
      </DatacModal>
      <DatacModal
        title={intlChart('unsaved_changes_title')}
        isOpened={isCloseConfirmVisible}
        cancelLabel={intl('common.cancel')}
        submitLabel={intl('common.confirm')}
        onClose={() => {
          setIsCloseConfirmVisible(false)
        }}
        onSubmit={onClose}
      >
        <div className="side_by_side-chart-wizard__close-confirm">{intlChart('unsaved_changes_description')}</div>
      </DatacModal>
    </Form>
  )
}
