import './AddProductModal.less'

import { Form, Input, InputNumber, RefSelectProps, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  Product,
  ProductStatus,
  ProductType,
  ProjectCategory,
  createFormula,
  fetchBrands,
  projectCategoryProductKeyMap
} from '../../../../../requests'
import { enumToOptions } from '../../../../../utils'
import { maxTitleLengthLong, validateRequired } from '../../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

interface AddProductModalProps {
  onClose: (redirect: boolean) => void
  isOpened: boolean
}

export const AddProductModal: React.FC<AddProductModalProps> = ({ onClose, isOpened }) => {
  const intlProducts = useScopedIntl('side_by_side.products.table')
  const intlFormula = useScopedIntl('side_by_side.products.formula')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const { project } = useSideBySideProjectDetailsStore()
  const [isMasterProduct, setIsMasterProduct] = useState(false)
  const [currentEnteredBrand, setCurrentEnteredBrand] = useState('')
  const [brand, setBrand] = useState('')
  const brandRef = useRef<RefSelectProps>()
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])

  useEffect(() => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }, [])

  useEffect(() => {
    if (!isOpened) return

    setIsMasterProduct(false)
    setBrand('')
    setCurrentEnteredBrand('')
    formInstance.resetFields()
  }, [isOpened])

  const onBrandSearch = (searchPhrase: string) => {
    setCurrentEnteredBrand(searchPhrase)
  }

  const setNewBrand = (brand: string) => {
    formInstance.setFieldsValue({ brand })
    setBrand(brand)
  }

  const onBrandKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredBrand.trim() ||
      brandOptions.find(({ value }) => value === currentEnteredBrand)
    ) {
      return
    }

    setBrandOptions(currentAvailableBrands => [
      ...currentAvailableBrands,
      { value: currentEnteredBrand, label: currentEnteredBrand }
    ])
    setNewBrand(currentEnteredBrand)
    onBrandSearch('')
    setCurrentEnteredBrand('')
    brandRef.current.blur()
  }

  const onBrandSelect = (selectedBrand: string) => {
    setNewBrand(brand === selectedBrand ? '' : selectedBrand)
  }
  const onSubmit = (data: Product) =>
    createFormula(
      { projectId: project.id, ...data, isMasterProduct },
      {
        onSuccess: () => onClose(true),
        onProductAlreadyExists: () =>
          DatacMessage.error(intlFormula('error.exists.title.add'), intlFormula('error.exists.description')),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )

  const isInputVisible = (key: keyof Product) =>
    projectCategoryProductKeyMap[project.category as ProjectCategory].includes(key)

  return (
    <DatacModal
      className="add-project-product-modal"
      isOpened={isOpened}
      onClose={() => onClose(false)}
      onSubmit={() => formInstance.submit()}
      title={intlFormula('add')}
      footerOption={
        <div className="add-project-product-modal__master">
          <Switch checked={isMasterProduct} onChange={setIsMasterProduct} />
          <span>{intlFormula('add_to_library')}</span>
        </div>
      }
    >
      <Form form={formInstance} name="editProductForm" onFinish={onSubmit}>
        <DatacFormItem
          name="formula"
          label={intlProducts('formula')}
          validate={validateRequired(intl('common.required'))}
          hidden={!isInputVisible('formula')}
          showAsterisk
        >
          <Input size="large" placeholder={intlProducts('formula.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem
          name="type"
          label={intlProducts('type')}
          validate={validateRequired(intl('common.required'))}
          hidden={!isInputVisible('type')}
          showAsterisk
        >
          <DatacSelect
            showSearch
            options={enumToOptions(ProductType, intlStatus)}
            placeholder={intlProducts('type.placeholder')}
          />
        </DatacFormItem>
        <DatacFormItem
          name="brand"
          label={intlProducts('brand')}
          validate={validateRequired(intl('common.required'))}
          hidden={!isInputVisible('brand')}
          showAsterisk
        >
          <DatacSelect
            size="large"
            placeholder={intlProducts('brand.placeholder')}
            showSearch
            options={brandOptions}
            onInputKeyDown={onBrandKeyPressed}
            onSearch={onBrandSearch}
            defaultActiveFirstOption={false}
            selectRef={brandRef}
            onSelect={onBrandSelect}
          />
        </DatacFormItem>
        <DatacFormItem
          name="productName"
          label={intlProducts('product_name')}
          validate={validateRequired(intl('common.required'))}
          hidden={!isInputVisible('productName')}
          showAsterisk
        >
          <Input size="large" placeholder={intlProducts('product_name.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem
          name="category"
          label={intlProducts('category')}
          validate={validateRequired(intl('common.required'))}
          hidden={!isInputVisible('category')}
          showAsterisk
        >
          <Input size="large" placeholder={intlProducts('category.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="technology" label={intlProducts('technology')} hidden={!isInputVisible('technology')}>
          <Input size="large" placeholder={intlProducts('technology.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="status" label={intlProducts('status')} hidden={!isInputVisible('status')}>
          <DatacSelect
            showSearch
            options={enumToOptions(ProductStatus, intlStatus)}
            placeholder={intlProducts('status.placeholder')}
          />
        </DatacFormItem>
        <DatacFormItem name="shadeName" label={intlProducts('shade_name')} hidden={!isInputVisible('shadeName')}>
          <Input size="large" placeholder={intlProducts('shade_name.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="batch" label={intlProducts('batch')} hidden={!isInputVisible('batch')}>
          <Input size="large" placeholder={intlProducts('batch.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="devVol" label={intlProducts('dev_vol')} hidden={!isInputVisible('devVol')}>
          <Input size="large" placeholder={intlProducts('dev_vol.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="mixRatio" label={intlProducts('mix_ratio')} hidden={!isInputVisible('mixRatio')}>
          <Input size="large" placeholder={intlProducts('mix_ratio.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem name="quantity" label={intlProducts('quantity')} hidden={!isInputVisible('quantity')}>
          <Input size="large" placeholder={intlProducts('quantity.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>
        <DatacFormItem
          name="processingTime"
          label={intlProducts('processing_time')}
          hidden={!isInputVisible('processingTime')}
        >
          <InputNumber size="large" placeholder={intlProducts('processing_time.placeholder')} min={0} />
        </DatacFormItem>
        <DatacFormItem
          name="specialInstructions"
          label={intlProducts('special_instructions')}
          hidden={!isInputVisible('specialInstructions')}
        >
          <Input
            size="large"
            placeholder={intlProducts('special_instructions.placeholder')}
            maxLength={maxTitleLengthLong}
          />
        </DatacFormItem>
        <DatacFormItem
          name="specialApplication"
          label={intlProducts('special_application')}
          hidden={!isInputVisible('specialApplication')}
        >
          <Input
            size="large"
            placeholder={intlProducts('special_application.placeholder')}
            maxLength={maxTitleLengthLong}
          />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
