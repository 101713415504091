import Zone11 from '../../../../../../assets/images/sbs/sbs_zone_1_1.svg'
import Zone21 from '../../../../../../assets/images/sbs/sbs_zone_2_1.svg'
import Zone22 from '../../../../../../assets/images/sbs/sbs_zone_2_2.svg'
import Zone31 from '../../../../../../assets/images/sbs/sbs_zone_3_1.svg'
import Zone32 from '../../../../../../assets/images/sbs/sbs_zone_3_2.svg'
import Zone33 from '../../../../../../assets/images/sbs/sbs_zone_3_3.svg'
import ZoneBottom2 from '../../../../../../assets/images/sbs/sbs_zone_bottom_2.svg'
import ZoneBottom3 from '../../../../../../assets/images/sbs/sbs_zone_bottom_3.svg'
import ZoneBottom3b from '../../../../../../assets/images/sbs/sbs_zone_bottom_3b.svg'
import ZoneBottom4 from '../../../../../../assets/images/sbs/sbs_zone_bottom_4.svg'
import ZoneTop21 from '../../../../../../assets/images/sbs/sbs_zone_top_2_1.svg'
import ZoneTop22 from '../../../../../../assets/images/sbs/sbs_zone_top_2_2.svg'
import ZoneTop31 from '../../../../../../assets/images/sbs/sbs_zone_top_3_1.svg'
import ZoneTop32 from '../../../../../../assets/images/sbs/sbs_zone_top_3_2.svg'
import ZoneTop33 from '../../../../../../assets/images/sbs/sbs_zone_top_3_3.svg'
import ZoneTop41 from '../../../../../../assets/images/sbs/sbs_zone_top_4_1.svg'
import ZoneTop42 from '../../../../../../assets/images/sbs/sbs_zone_top_4_2.svg'
import ZoneTop43 from '../../../../../../assets/images/sbs/sbs_zone_top_4_3.svg'
import ZoneTop44 from '../../../../../../assets/images/sbs/sbs_zone_top_4_4.svg'
import { ProductType, ProjectCategory } from '../../../../../../requests'

enum ZonePosition {
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  FrontLeft = 'FRONT_LEFT',
  FrontRight = 'FRONT_RIGHT',
  BackLeft = 'BACK_LEFT',
  BackRight = 'BACK_RIGHT',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  TopMiddle = 'TOP_MIDDLE',
  BottomMiddle = 'BOTTOM_MIDDLE',
  TopMiddleLeft = 'TOP_MIDDLE_LEFT',
  TopMiddleRight = 'TOP_MIDDLE_RIGHT',
  BottomMiddleLeft = 'BOTTOM_MIDDLE_LEFT',
  BottomMiddleRight = 'BOTTOM_MIDDLE_RIGHT'
}

export interface ZoneArea {
  position: ZonePosition
  component: React.FC
  type?: ProductType
}
interface ZoneAreasMap {
  categories: ProjectCategory[]
  areas: ZoneArea[]
}

export const zoneAreasMap: ZoneAreasMap[] = [
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [{ position: ZonePosition.Middle, component: Zone11 }]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.Left, component: Zone21 },
      { position: ZonePosition.Right, component: Zone22 }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.Left, component: Zone31 },
      { position: ZonePosition.Middle, component: Zone32 },
      { position: ZonePosition.Right, component: Zone33 }
    ]
  },
  {
    categories: [ProjectCategory.Haircare, ProjectCategory.Styling],
    areas: [
      { position: ZonePosition.FrontLeft, component: ZoneTop21 },
      { position: ZonePosition.FrontRight, component: ZoneTop22 },
      { position: ZonePosition.BackLeft, component: ZoneBottom2 },
      { position: ZonePosition.BackRight, component: ZoneBottom2 }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.TopLeft, component: ZoneTop21 },
      { position: ZonePosition.TopRight, component: ZoneTop22 },
      { position: ZonePosition.BottomLeft, component: ZoneBottom2 },
      { position: ZonePosition.BottomRight, component: ZoneBottom2 }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.TopLeft, component: ZoneTop21 },
      { position: ZonePosition.TopRight, component: ZoneTop22 },
      { position: ZonePosition.BottomLeft, component: ZoneBottom3b },
      { position: ZonePosition.BottomMiddle, component: ZoneBottom3b },
      { position: ZonePosition.BottomRight, component: ZoneBottom3b }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.TopLeft, component: ZoneTop31 },
      { position: ZonePosition.TopMiddle, component: ZoneTop32 },
      { position: ZonePosition.TopRight, component: ZoneTop33 },
      { position: ZonePosition.BottomLeft, component: ZoneBottom3 },
      { position: ZonePosition.BottomMiddle, component: ZoneBottom3 },
      { position: ZonePosition.BottomRight, component: ZoneBottom3 }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.TopLeft, component: ZoneTop41 },
      { position: ZonePosition.TopMiddleLeft, component: ZoneTop42 },
      { position: ZonePosition.TopMiddleRight, component: ZoneTop43 },
      { position: ZonePosition.TopRight, component: ZoneTop44 },
      { position: ZonePosition.BottomLeft, component: ZoneBottom3 },
      { position: ZonePosition.BottomMiddle, component: ZoneBottom3 },
      { position: ZonePosition.BottomRight, component: ZoneBottom3 }
    ]
  },
  {
    categories: [ProjectCategory.HairColor, ProjectCategory.Haircare, ProjectCategory.Styling, ProjectCategory.Texture],
    areas: [
      { position: ZonePosition.TopLeft, component: ZoneTop41 },
      { position: ZonePosition.TopMiddleLeft, component: ZoneTop42 },
      { position: ZonePosition.TopMiddleRight, component: ZoneTop43 },
      { position: ZonePosition.TopRight, component: ZoneTop44 },
      { position: ZonePosition.BottomLeft, component: ZoneBottom4 },
      { position: ZonePosition.BottomMiddleLeft, component: ZoneBottom4 },
      { position: ZonePosition.BottomMiddleRight, component: ZoneBottom4 },
      { position: ZonePosition.BottomRight, component: ZoneBottom4 }
    ]
  }
]

export const topPositions = [
  ZonePosition.TopLeft,
  ZonePosition.TopMiddleLeft,
  ZonePosition.TopMiddle,
  ZonePosition.TopMiddleRight,
  ZonePosition.TopRight,
  ZonePosition.FrontLeft,
  ZonePosition.FrontRight
]
