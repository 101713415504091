import classNames from 'classnames'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { DatacIcon } from '../../../../common'

interface Props {
  label: string
  count: number
  total: number
  all: boolean
  isActive: boolean
  isLoading: boolean
  onClick: () => void
}

export const ChartWizardSection: React.FC<Props> = ({ label, count, total, all, isActive, isLoading, onClick }) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const totalStr = all ? '' : total === null ? '' : ` / ${total}`
  return (
    <div
      className={classNames('side_by_side-chart-wizard__sidebar__section', {
        'side_by_side-chart-wizard__sidebar__section--active': isActive,
        'side_by_side-chart-wizard__sidebar__section--loading': isLoading
      })}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div className="side_by_side-chart-wizard__sidebar__section__details">
        <strong>{label}</strong>
        <div
          className={classNames('side_by_side-chart-wizard__sidebar__section__badge', {
            'side_by_side-chart-wizard__sidebar__section__badge--blue': all || count
          })}
        >
          <DatacIcon raw name="check" />
          <span>
            {all && intlChart('all')}
            {!all && `${count || 0}${totalStr}`}
          </span>
        </div>
      </div>
      <DatacIcon name="chevronRight" type="grey" />
    </div>
  )
}
