import './TestZonesSelected.less'

import classNames from 'classnames'
import React from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Product } from '../../../../../../requests'
import { DatacIcon } from '../../../../../common'
import { ZoneArea } from './testZoneAreas'

interface SelectedProductProps {
  product: Product
  area: ZoneArea
  number: number
  onRemove: () => void
  onClick: () => void
  onMoveLeft?: () => void
  onMoveRight?: () => void
  isSelected: boolean
}

export const SelectedProduct: React.FC<SelectedProductProps> = ({
  product,
  area,
  number,
  onRemove,
  onClick,
  onMoveLeft,
  onMoveRight,
  isSelected
}) => {
  const intlTable = useScopedIntl('side_by_side.products.table')
  const intlStatus = useScopedIntl('status')
  const intlPosition = useScopedIntl('side_by_side.project.test.zones.position')

  return (
    <div className="test-zones-selected__product" onClick={onClick} onMouseOver={onClick}>
      <div className="test-zones-selected__product__header">
        <div className="test-zones-selected__product__header__left">
          <div
            className={classNames(
              'test-zones-selected__product__header__type',
              `test-zones-selected__product__header__type--${product.type.toLowerCase()}`
            )}
          >
            {intlStatus(product.type.toLowerCase())}
          </div>
          <div className="test-zones-selected__product__header__formula">
            <div className="test-zones-selected__product__header__formula__label">{intlTable('formula')}:</div>
            <div className="test-zones-selected__product__header__formula__value">{product.formula || '-'}</div>
          </div>
        </div>
        {isSelected ? (
          <DatacIcon name="trash" onClick={onRemove} />
        ) : (
          <div
            className={classNames(
              'test-zones-selected__number',
              `test-zones-selected__number--${area?.type?.toLowerCase()}`
            )}
          >
            {number}
          </div>
        )}
      </div>
      <div className="test-zones-selected__product__row">
        <div className="test-zones-selected__product__row__label">{intlTable('product_name')}:</div>
        <div className="test-zones-selected__product__row__value">{product.productName || '-'}</div>
      </div>
      <div className="test-zones-selected__product__row">
        <div className="test-zones-selected__product__row__label">{intlTable('category')}:</div>
        <div className="test-zones-selected__product__row__value">{product.category || '-'}</div>
      </div>
      <div className="test-zones-selected__product__footer">
        <div className="test-zones-selected__product__footer__position">{intlPosition(area?.position)}</div>
        <DatacIcon
          name="arrowLeft"
          onClick={onMoveLeft}
          className={!onMoveLeft && 'test-zones-selected__product__disabled-icon'}
        />
        <DatacIcon
          name="arrowRight"
          onClick={onMoveRight}
          className={!onMoveRight && 'test-zones-selected__product__disabled-icon'}
        />
      </div>
    </div>
  )
}
