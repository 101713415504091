import './TestFormEnd.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import Completed from '../../../../../../assets/images/document-complete.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { endSideBySideTest } from '../../../../../../requests'
import { routes } from '../../../../../../routes'
import { DatacMessage, DatacTitle } from '../../../../../common'
import { useSideBySideProjectDetailsStore } from '../../../SideBySideProjectDetailsStore'

interface Props {
  testId: string
  onBack: () => void
}
export const TestFormEnd: React.FC<Props> = ({ testId, onBack }) => {
  const intlEnd = useScopedIntl('side_by_side.project.test.end')
  const intlCommon = useScopedIntl('common')
  const intl = useScopedIntl('')
  const { project } = useSideBySideProjectDetailsStore()

  const onEndTest = () => {
    endSideBySideTest(
      { testId, projectId: project.id },
      {
        onSuccess: () => navigate(routes.sideBySideProject(project.id)),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <div className="test-form-end">
      <Completed />
      <DatacTitle size="medium">{intlEnd('description.1')}</DatacTitle>
      <div className="test-form-end__description">{intlEnd('description.2')}</div>
      <div className="test-form-end__description">{intlEnd('description.3')}</div>

      <div className="test-form-end__footer">
        <Button size="large" type="default" onClick={onBack}>
          {intlCommon('back')}
        </Button>
        <Button size="large" type="primary" onClick={onEndTest}>
          {intlEnd('next')}
        </Button>
      </div>
    </div>
  )
}
