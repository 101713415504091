import { ColumnConfig, RadarConfig } from '@ant-design/plots'

import { SideBySideChart, SideBySideChartData, SideBySideChartFormula } from '../../../../requests'

const maxLabelLength = 30
const trimQuestionLabel = (label: string) =>
  label.length > maxLabelLength ? `${label.substring(0, maxLabelLength - 3)}...` : label

const formulaFormatter = (formulas: SideBySideChartFormula[]) => (formulaId: string) =>
  formulas.find(formula => `${formula.id}` === formulaId)?.formula_number

const legendFormatter = (formulas: SideBySideChartFormula[]) => (index: number) => formulas[index].category_name || '-'

const questionFormatter = (questions: SideBySideChart['questions'], trim?: boolean) => (questionId: string) => {
  const label = questions.find(question => `${question.id}` === questionId)?.title
  return trim ? trimQuestionLabel(label) : label
}

const getChartConfig = (data: SideBySideChartData[], questionCount: number) => ({
  data,
  xField: questionCount > 1 ? 'question_id' : 'formula_id',
  yField: 'value',
  seriesField: 'formula_id'
})

export const getColumnConfig = (
  data: SideBySideChartData[],
  formulas: SideBySideChartFormula[],
  questions: {
    id: string
    title: string
    variable: string
  }[],
  axis: SideBySideChart['axis']
) =>
  ({
    ...getChartConfig(data, questions.length),
    isGroup: true,
    dodgePadding: data.length > 20 ? 4 : 14,
    padding: 'auto',
    color: formulas.map(formula => formula.color),
    columnStyle: {
      radius: [4, 4, 0, 0]
    },
    xAxis: {
      tickLine: {
        style: {
          opacity: 0
        }
      },
      label: {
        formatter: questions.length > 1 ? questionFormatter(questions, true) : formulaFormatter(formulas),
        autoRotate: true
      }
    },
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 4]
          }
        }
      },
      ...(axis ? { max: axis.max } : {})
    },
    legend: {
      position: 'bottom',
      itemHeight: 30,
      padding: [70, 0, 0, 0],
      itemName: {
        style: {
          fontSize: 14,
          fontWeight: 500,
          y: 7
        },
        formatter: formulaFormatter(formulas)
      },
      itemValue: {
        formatter(text, item, index) {
          return legendFormatter(formulas)(index)
        },
        style: {
          fillOpacity: 0.5,
          fontWeight: 500,
          x: 16,
          y: 24
        }
      },
      marker: {
        style: {
          y: 14
        }
      }
    },
    tooltip: {
      customItems(originalItems) {
        return originalItems.map(item => ({
          ...item,
          name: item.data.formula
        }))
      },
      title(title, datum) {
        return questions.length > 1 ? questionFormatter(questions)(title) : data[0].question
      }
    }
  } as ColumnConfig)

export const getRadarConfig = (
  data: SideBySideChartData[],
  formulas: SideBySideChartFormula[],
  questions: {
    id: string
    title: string
    variable: string
  }[],
  axis: SideBySideChart['axis']
) =>
  ({
    ...getChartConfig(data, questions.length),
    padding: [30, 30, 120, 30],
    color: formulas.map(formula => formula.color),
    xAxis: {
      line: {
        style: {
          opacity: 0
        }
      },
      tickLine: {
        style: {
          opacity: 0
        }
      },
      label: {
        formatter: questionFormatter(questions)
      }
    },
    yAxis: {
      tickLine: {
        style: {
          opacity: 0
        }
      },
      min: axis ? axis.min : 0,
      ...(axis ? { max: axis.max } : {}),
      grid: {
        line: {
          type: 'line'
        },
        alternateColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    legend: {
      position: 'bottom',
      itemHeight: 40,
      itemName: {
        style: {
          fontSize: 14,
          fontWeight: 500,
          y: 12
        },
        formatter: formulaFormatter(formulas)
      },
      itemValue: {
        formatter(text, item, index) {
          return legendFormatter(formulas)(index)
        },
        style: {
          fillOpacity: 0.5,
          fontWeight: 500,
          x: 32,
          y: 28
        }
      }
    },
    tooltip: {
      customItems(originalItems) {
        return originalItems.map(item => ({
          ...item,
          name: item.data.formula
        }))
      },
      title(title, datum) {
        return questionFormatter(questions)(title)
      }
    },
    xField: 'question_id'
  } as RadarConfig)

export const getColumnMinimalConfig = (
  data: SideBySideChartData[],
  formulas: SideBySideChartFormula[],
  questionCount: number,
  axis: SideBySideChart['axis']
) =>
  ({
    ...getChartConfig(data, questionCount),
    isGroup: questionCount > 1,
    dodgePadding: data.length > 20 ? 1 : 6,
    padding: [0, 0],
    color: formulas.map(formula => formula.color),
    columnStyle: {
      radius: [4, 4, 4, 4]
    },
    xAxis: {
      line: {
        style: {
          opacity: 0
        }
      },
      tickLine: {
        style: {
          opacity: 0
        }
      },
      label: {
        style: {
          fontSize: 0
        }
      }
    },
    yAxis: {
      tickLine: {
        style: {
          opacity: 0
        }
      },
      label: {
        style: {
          width: 0,
          fontSize: 0
        }
      },
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      },
      ...(axis ? { max: axis.max } : {})
    },
    legend: false,
    tooltip: false
  } as ColumnConfig)

export const getRadarMinimalConfig = (
  data: SideBySideChartData[],
  formulas: SideBySideChartFormula[],
  questionCount: number,
  axis: SideBySideChart['axis']
) =>
  ({
    ...getChartConfig(data, questionCount),
    padding: [0, 0],
    color: formulas.map(formula => formula.color),
    xAxis: {
      line: {
        style: {
          opacity: 0
        }
      },
      tickLine: {
        style: {
          opacity: 0
        }
      },
      label: {
        style: {
          fontSize: 0
        }
      }
    },
    yAxis: {
      label: {
        style: {
          width: 0,
          fontSize: 0
        }
      },
      tickLine: {
        style: {
          opacity: 0
        }
      },
      grid: {
        line: {
          type: 'line'
        }
      },
      min: axis ? axis.min : 0,
      ...(axis ? { max: axis.max } : {})
    },
    legend: false,
    tooltip: false
  } as RadarConfig)

export const getDummyColumnConfig = () => {
  const fakeData = [
    {
      formula: 'f1',
      question: 'q1',
      value: 4.8
    },
    {
      formula: 'f2',
      question: 'q1',
      value: 0.5
    }
  ] as SideBySideChartData[]
  const fakeFormulas = [
    {
      formula_number: 'f1',
      color: '#000'
    },
    {
      formula_number: 'f2',
      color: '#000'
    }
  ] as SideBySideChartFormula[]
  const fakeQuestions = [
    {
      id: '1',
      title: 'q1',
      variable: 'Q1'
    },
    {
      id: '2',
      title: 'q2',
      variable: 'Q2'
    }
  ]
  return {
    ...getColumnConfig(fakeData, fakeFormulas, fakeQuestions, null),
    padding: [40, 40, 60, 40],
    columnStyle: {
      opacity: 0
    },
    xAxis: {
      label: {
        style: {
          fontSize: 0
        }
      }
    },
    legend: false,
    tooltip: false
  } as ColumnConfig
}
