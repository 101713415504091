import { Button } from 'antd'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { DatacIcon } from '../../../../common'

interface Props {
  onCancel: () => void
  onSave: () => void
  canSave: boolean
}

export const ChartWizardHeader: React.FC<Props> = ({ onCancel, onSave, canSave }) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const intlCommon = useScopedIntl('common')
  return (
    <div className="side_by_side-chart-wizard__header">
      <strong>{intlChart('title')}</strong>
      <Button onClick={onCancel}>{intlCommon('cancel')}</Button>
      <Button onClick={onSave} type="primary" disabled={!canSave}>
        {intlCommon('save')}
      </Button>
      <DatacIcon className="datac-modal__close-icon" name="x" type="grey" onClick={onCancel} />
    </div>
  )
}
