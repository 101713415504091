import './ProductInfoRecord.less'

import { Input } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { DatacIcon, DatacIconName } from '../../../../../common'

interface ProductInfoRecordProps {
  label: string
  value: string
  icon: DatacIconName
  setValue?: (value: string) => void
}

export const ProductInfoRecord: React.FC<ProductInfoRecordProps> = ({ label, value, icon, setValue }) => {
  const intlProducts = useScopedIntl('side_by_side.products.table')
  const [currentValue, setCurrentValue] = useState(value)
  const [inputValue, setInputValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    setInputValue(value)
    setIsEditing(false)
  }, [value])

  useEffect(() => {
    if (!isEditing || !inputRef.current) return

    inputRef.current.focus()
  }, [isEditing, inputRef.current])

  const onEdit = () => {
    if (!setValue || isEditing) return

    setIsEditing(true)
    setCurrentValue(inputValue)
  }

  const onSave = () => {
    if (!setValue) return

    setValue(currentValue || value)
    setIsEditing(false)
  }

  return (
    <div
      className={classNames(
        'product-info-record',
        isEditing && 'product-info-record--active',
        setValue && 'product-info-record--has-border'
      )}
      onClick={onEdit}
    >
      {!isEditing && <DatacIcon name={icon} type="blue" />}
      <div className="product-info-record__content">
        <div className="product-info-record__label">{intlProducts(label)}</div>
        {isEditing ? (
          <Input
            value={currentValue}
            onChange={e => setCurrentValue(e.currentTarget.value)}
            className="product-info-record__input"
            ref={inputRef}
          />
        ) : (
          <div className="product-info-record__value">{value || '-'}</div>
        )}
      </div>
      {isEditing && <DatacIcon name="check" type="blue" onClick={onSave} className="product-info-record__save" />}
      {isEditing && (
        <DatacIcon name="x" type="red" onClick={() => setIsEditing(false)} className="product-info-record__close" />
      )}
    </div>
  )
}
