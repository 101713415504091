import './AssignProductsModal.less'

import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { assignToProject, fetchBrands, fetchGlobalProducts } from '../../../../../requests'
import { DatacMessage, DatacModal, DatacOption } from '../../../../common'
import { ProductTableSearchProps, ProductsTable } from '../../../../shared/ProductsTable'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

const pageSize = 50

interface AssignProductsModalProps {
  onClose: (redirect: boolean) => void
  isOpened: boolean
}

export const AssignProductsModal: React.FC<AssignProductsModalProps> = ({ isOpened, onClose }) => {
  const intl = useScopedIntl('')
  const intlFormula = useScopedIntl('side_by_side.products.formula')
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])
  const { project } = useSideBySideProjectDetailsStore()
  const [productsTableSearchProps, setProductsTableSearchProps] = useState<ProductTableSearchProps>()

  useEffect(() => {
    if (isOpened) updateBrandList()
  }, [isOpened])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }

  const onAssign = () => {
    assignToProject(
      {
        projectId: project.id,
        ...productsTableSearchProps
      },
      {
        onSuccess: () => onClose(true),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const productsCount = productsTableSearchProps?.productsCount

  return (
    <DatacModal
      className="assign-project-products-modal"
      isOpened={isOpened}
      onClose={() => onClose(false)}
      onSubmit={onAssign}
      noSubmit={!productsCount}
      submitLabel={intlFormula(`assign.submit${productsCount === 1 ? '.one' : ''}`, {
        count: productsCount
      })}
      title={intlFormula('assign.title')}
      destroyOnClose
    >
      <ProductsTable
        pageSize={pageSize}
        fetchProducts={fetchGlobalProducts}
        brandOptions={brandOptions}
        inModal
        hasShadeSearch
        setProductTableSearchProps={setProductsTableSearchProps}
      />
    </DatacModal>
  )
}
