import './SideBySideProjectTestLayout.less'

import { navigate } from 'gatsby-plugin-react-intl'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { SideBySideTest } from '../../../../../requests'
import { RouteStrings, routes } from '../../../../../routes'
import { DatacIcon, DatacTitle } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { SideBySideProjectTestPanelist } from '../SideBySideProjectTestPanelist'

interface Props {
  children: React.ReactNode
  test: SideBySideTest
  setTest: (test: SideBySideTest) => void
  menuContent?: ReactNode
}

export const SideBySideProjectTestLayout: React.FC<Props> = ({ children, menuContent, test, setTest }) => {
  const intl = useScopedIntl('')
  const { project, setTestContentRef } = useSideBySideProjectDetailsStore()
  const testContentRef = useRef<HTMLDivElement>(null)
  const [shouldUseHistory, setShouldUseHistory] = useState(false)

  useEffect(() => {
    if (!testContentRef.current) return
    setTestContentRef(testContentRef.current)
  }, [testContentRef.current])

  useEffect(() => {
    if (window.location.hash === RouteStrings.UseHistoryBack) {
      setShouldUseHistory(true)
      window.history.replaceState(null, null, window.location.pathname + window.location.search)
    }
  }, [])

  const onBack = () => {
    if (shouldUseHistory) {
      window.history.back()
    } else {
      navigate(routes.sideBySideProject(project.id))
    }
  }

  return (
    <div className="test-layout">
      <div className="test-layout__menu">
        <div className="test-layout__menu__wrapper">
          <div onClick={onBack} className="test-layout__go-back">
            <DatacIcon className="test-layout__back-icon" name="arrowLeft" type="blue" size="small" />
            {intl('common.back')}
          </div>
          <div className="test-layout__header">
            <DatacTitle type="h2" size="small">
              {project?.name}
            </DatacTitle>
          </div>
          <SideBySideProjectTestPanelist test={test} setTest={setTest} />
          <div className="test-layout__menu-content">{menuContent}</div>
        </div>
      </div>
      <main className="test-layout__content" ref={testContentRef}>
        {children}
      </main>
    </div>
  )
}
