import { Drawer, Input } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Product, SelectionKeys, SideBySideChartFormula } from '../../../../../requests'
import { DatacIcon, DatacMessage, DatacTable, baseColors } from '../../../../common'
import { getColumns } from '../../../../shared/ProductsTable/ProductsTableConfig'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

const MAX_SELECTED_FORMULAS = 25

interface Props {
  open: boolean
  formulas: Product[]
  selectedFormulas: SideBySideChartFormula[]
  onClose: () => void
  onSearch: (search: string) => void
  setSelectedFormulas: (selected: SideBySideChartFormula[]) => void
}

export const FormulasTableDrawer: React.FC<Props> = ({
  open,
  formulas,
  selectedFormulas,
  onClose,
  onSearch,
  setSelectedFormulas
}) => {
  const intl = useScopedIntl('')
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const intlTable = useScopedIntl('side_by_side.products.table')

  const [selected, setSelected] = useState<SelectionKeys>(selectedFormulas ? selectedFormulas.map(f => f.id) : [])
  const { project } = useSideBySideProjectDetailsStore()

  const onSetSelected = (val: SelectionKeys) => {
    if (val.length > MAX_SELECTED_FORMULAS) {
      DatacMessage.warning(
        intlChart('too_many_formulas_title'),
        intlChart('too_many_formulas_description', { count: MAX_SELECTED_FORMULAS })
      )
      return
    }
    setSelected(val)
  }

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const onChange = (val: SelectionKeys) => {
    const newSelected = val.reduce((acc, id) => {
      if (selectedFormulas.find(formula => `${formula.id}` === `${id}`)) {
        return acc
      }
      const newSelectedFormula = formulas.find(formula => `${formula.id}` === `${id}`)
      if (!newSelectedFormula) return acc
      return [
        ...acc,
        {
          brand_name: newSelectedFormula.brand,
          color: getDefaultColor(),
          formula_number: newSelectedFormula.formula,
          category_name: newSelectedFormula.category,
          id: newSelectedFormula.id,
          order: 0
        }
      ] as SideBySideChartFormula[]
    }, [] as SideBySideChartFormula[])
    setSelectedFormulas(
      selectedFormulas
        .filter(formula => val.find(id => `${formula.id}` === `${id}`))
        .concat(newSelected)
        .map((formula, index) => ({
          ...formula,
          order: index
        }))
    )
  }

  const getDefaultColor = () => {
    if (!selectedFormulas?.length) return baseColors[0]
    const usedColors = selectedFormulas.map(formula => formula.color)
    const notUsedYet = baseColors.find(color => !usedColors.includes(color))
    if (notUsedYet) return notUsedYet
    return baseColors[selectedFormulas.length % baseColors.length]
  }

  const columnWidths: Record<string, string> = {
    formula: '17rem',
    productName: '19rem',
    category: '15rem',
    brand: '12rem'
  }

  const columns = getColumns({
    columnNames: {
      formula: intlTable('formula'),
      category: intlTable('category'),
      brand: intlTable('brand'),
      type: intlTable('type'),
      productName: intlTable('product_name'),
      technology: intlTable('technology'),
      status: intlTable('status'),
      shadeName: intlTable('shade_name'),
      batch: intlTable('batch'),
      devVol: intlTable('dev_vol'),
      mixRatio: intlTable('mix_ratio'),
      quantity: intlTable('quantity'),
      processingTime: intlTable('processing_time'),
      specialInstructions: intlTable('special_instructions'),
      specialApplication: intlTable('special_application'),
      active: intl('status.active')
    },
    projectCategory: project.category
  }).map(column => ({
    ...column,
    ...(columnWidths[column.key] ? { width: columnWidths[column.key] } : {})
  }))

  const onSearchChange = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, 1000),
    []
  )

  return (
    <Drawer
      open={open}
      placement="left"
      getContainer={false}
      onClose={onClose}
      className="side_by_side-chart-wizard__drawer side_by_side-chart-wizard__drawer--table"
      width="min-content"
    >
      <div className="side_by_side-chart-wizard__drawer__header">
        <strong>{intlChart(`field.formulas.label`)}</strong>
        <DatacIcon name="chevronLeft" onClick={onClose} />
      </div>
      <div className="side_by_side-chart-wizard__drawer__table-search">
        <Input
          onChange={e => {
            onSearchChange(e.target.value)
          }}
          placeholder={intlChart('formula.search.placeholder')}
          allowClear
        />
      </div>
      <DatacTable dataSource={formulas} selectedRows={selected} setSelectedRows={onSetSelected} columns={columns} />
    </Drawer>
  )
}
