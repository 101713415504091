import './NumberQuestionControl.less'

import { InputNumber } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import { NumberQuestion } from '../../../../../../../requests'
import { preventNonNumericKeyPress } from '../../../../../../../utils'
import { DatacIcon } from '../../../../../../common'

interface Props {
  question: NumberQuestion
  onChange?: (value: number | string) => void
  value?: number
  disabled?: boolean
}

const hasWrongDecimal = (value: number, question: NumberQuestion) =>
  typeof value === 'number' &&
  question.config?.maxDecimal != null &&
  value !== Math.round(value * Math.pow(10, question.config?.maxDecimal)) / Math.pow(10, question.config?.maxDecimal)

export const isNumberQuestionAnswerWrong = (value: number, question: NumberQuestion) =>
  (value != null && (value > question.config?.maxValue || value < question.config?.minValue)) ||
  hasWrongDecimal(value, question)

export const NumberQuestionControl: React.FC<Props> = ({ question, onChange, value, disabled = false }) => {
  const intlNumberQuestion = useScopedIntl('studies.fulfillment.question.number')
  const onInputChange = (newValue: React.ReactText) => {
    if (newValue === value) return

    if (newValue === null || typeof newValue === 'number') {
      onChange(newValue)
    }
  }

  const hasError = useMemo(() => isNumberQuestionAnswerWrong(value, question), [value])

  return (
    <>
      <div
        className={classNames('test-form-number-question-control', {
          'test-form-number-question-control--error': hasError
        })}
      >
        <InputNumber
          className={classNames('test-form-number-question-control__input', {
            'test-form-number-question-control__input--with-unit': !!question.config.unit
          })}
          size="large"
          placeholder={disabled ? undefined : question.config?.placeholder}
          onChange={onInputChange}
          value={value}
          disabled={disabled}
          changeOnWheel={false}
          decimalSeparator="."
          onKeyPress={preventNonNumericKeyPress}
        />
        {!!question.config.unit && (
          <div
            className={classNames('test-form-number-question-control__unit-box', {
              'test-form-number-question-control__unit-box--disabled': disabled
            })}
          >
            <span className="test-form-number-question-control__unit">{question.config.unit}</span>
          </div>
        )}
      </div>
      {hasError && (
        <span className="test-form-number-question-control__error">
          <DatacIcon name="alertCircle" raw className="test-form-number-question-control__error-icon" />
          <div className="test-form-number-question-control__error__messages">
            {value > question.config?.maxValue && (
              <div>{intlNumberQuestion('max_value_validation', { value: question.config.maxValue })}</div>
            )}
            {value < question.config?.minValue && (
              <div>{intlNumberQuestion('min_value_validation', { value: question.config.minValue })}</div>
            )}
            {hasWrongDecimal(value, question) && (
              <div>{intlNumberQuestion('max_decimal_validation', { value: question.config.maxDecimal })}</div>
            )}
          </div>
        </span>
      )}
    </>
  )
}
