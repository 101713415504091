import './TestFormInstruction.less'

import { ContentState } from 'draft-js'
import React, { useEffect, useRef } from 'react'

import { DatacIcon } from '../../../../../common'
import { EditorOutput } from '../../../../../shared/Editor'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from '../TestFormUtils'

interface Props {
  instruction: ContentState
  isGlobal: boolean
}

const contextProps: PropsFromContextKeys = ['record', 'columnWidth']

export const TestFormInstructionInner: React.FC<Props & PropsFromContext> = ({
  instruction,
  isGlobal,
  record,
  columnWidth
}) => {
  const globalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!globalRef.current || !wrapperRef.current || !instruction) return
    wrapperRef.current.style.height = `${globalRef.current.clientHeight}px`
  }, [globalRef.current, wrapperRef.current, instruction, columnWidth])

  return isGlobal ? (
    <div className="test-form-instruction__wrapper" ref={wrapperRef}>
      <div className="test-form-instruction__global" ref={globalRef}>
        <div className="test-form-instruction__block" style={{ width: '100%' }}>
          <div className="test-form-instruction__block__inner">
            <DatacIcon name="alertCircle" raw className="test-form-instruction__block__icon" />
            <EditorOutput content={instruction} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="test-form-instruction">
      {record.products.map(product => (
        <div
          className="test-form-instruction__block"
          key={product.id}
          style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
        >
          <div className="test-form-instruction__block__inner">
            <DatacIcon name="alertCircle" raw className="test-form-instruction__block__icon" />
            <EditorOutput content={instruction} />
          </div>
        </div>
      ))}
    </div>
  )
}
export const TestFormInstruction = TestFormContextWrapper<Props>(TestFormInstructionInner, contextProps)
