import './ProjectsList.less'

import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { Project } from '../../../../requests'
import { routes } from '../../../../routes'
import { ProjectCard } from './ProjectCard'

interface Props {
  projects: Project[]
}

export const ProjectsList: React.FC<Props> = ({ projects }) => {
  return (
    <ul className="projects-list">
      {projects.map(project => (
        <li
          className="projects-list__item"
          onClick={() => navigate(routes.sideBySideProject(project.id))}
          key={project.id}
        >
          <ProjectCard project={project} />
        </li>
      ))}
    </ul>
  )
}
